import React, { FC } from 'react';
import styled from 'styled-components';
import SuitImage from '../images/suit-image';

const LogoContainer = styled.div`
  display: none;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: flex;
    padding: 1em 0;
    height: 85px
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.grey};
  padding-bottom: 20px;
`;

const ImageContainer = styled.div`
  clip-path: ellipse(100% 92% at 50% 8%);

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    height: 276px;
  }
`;

const Header: FC = () => (
  <>
    <LogoContainer />
    <Wrapper>
      <ImageContainer>
        <SuitImage />
      </ImageContainer>
    </Wrapper>
  </>
);

export default Header;
