import styled from "styled-components";

export const Container = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  padding: 40px 10px 15px;
  overflow: hidden;
  text-align: center;
  position: relative;
  display: block;
  padding-bottom: 30px;

  @media (max-width: ${({ theme: { breakpoints } }): string => breakpoints.tabletValue}px) {
    padding: unset;
  }

  @media (max-width: ${({ theme: { breakpoints } }): string => breakpoints.phoneValue}px) {
    padding: unset;
  }
`;

export const ColumnLeft = styled.div`
  visibility: visible;
  border-right: 1px solid ${({ theme: { colors } }): string => colors.lightGrey };;
  padding: 0 50px;
  float: left;
  width: 50%;
  box-sizing: border-box;

  @media (max-width: ${({ theme: { breakpoints } }): string => breakpoints.tabletValue}px) {
    width: unset;
    float: unset;
    margin-top: 5%;
    border-right: unset;
  }

  @media (max-width: ${({ theme: { breakpoints } }): string => breakpoints.phoneValue}px) {
    width: unset;
    float: unset;
    margin-top: 5%;
    border-right: unset;
  }
`;

export const ColumnRight = styled.div`
  visibility: visible;
  border-left: 1px solid ${({ theme: { colors } }): string => colors.lightGrey };
  padding: 0 50px;
  float: left;
  width: 50%;
  box-sizing: border-box;

  @media (max-width: ${({ theme: { breakpoints } }): string => breakpoints.tabletValue}px) {
    width: unset;
    margin-top: 5%;
    float: unset;
    border-left: unset;
  }

  @media (max-width: ${({ theme: { breakpoints } }): string => breakpoints.phoneValue}px) {
    width: unset;
    margin-top: 5%;
    float: unset;
    border-left: unset;
  }
`;

export const TextContainer = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  color: ${({ theme: { colors } }): string => colors.primary};
  height: 143px;
  overflow: hidden;
`;

export const Title = styled.h2`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  color: ${({ theme: { colors } }): string => colors.primary };
  margin: 0 0 10px;
  font-size: 1.75em;
  font-weight: normal;
  visibility: visible;
`;

export const Text = styled.strong`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  color: ${({ theme: { colors } }): string => colors.lightGrey };
  margin: 0 0 50px;
  font-size: 1.125em;
  line-height: 1.4;
  display: block;
  font-weight: normal;
  visibility: visible;
`;

export const FormContainer = styled.form`
  margin: -20px 0 15px;
  padding: 0;
  background: transparent;
  position: relative;
  width: 80%;
  display: inline-block;
  border-radius: 3px;
  border-style: none;

  @media (max-width: ${({ theme: { breakpoints } }): string => breakpoints.phoneValue}px) {
    width: 100%;
  }
`;

export const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border-style: none;
`;

export const InputContainer = styled.div`
  overflow: hidden;
`;

export const Input = styled.input`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  display: inline-block;
  border-radius: 26px 26px 0 0;
  border-bottom: 0;

  border: 1px solid #ccd4df;
  width: 100%;
  color: #3c4858;
  background: #fff;
  box-sizing: border-box;
  padding: 14px 19px;
  outline: none;
`;

export const InputButtonLeft = styled.input`
  float: none;
  margin: 0;
  text-align: center;
  padding: 14px 30px;
  white-space: inherit;
  background: ${({ theme: { colors } }): string => colors.white };
  border: 1px solid ${({ theme: { colors } }): string => colors.secondary };
  cursor: pointer;
  color: ${({ theme: { colors } }): string => colors.secondary };
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  min-width: 236px;
  border-radius: 0 0 26px 26px;
  width: 100%;
  outline: none;
`;

export const InputButtonRight = styled.input`
  float: none;
  margin: 0;
  text-align: center;
  padding: 14px 30px;
  white-space: inherit;
  border: 1px solid #02cd89;
  cursor: pointer;
  color: ${({ theme: { colors } }): string => colors.white };
  background: ${({ theme: { colors } }): string => colors.secondary };
  border-color: ${({ theme: { colors } }): string => colors.secondary };
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  min-width: 236px;
  border-radius: 0 0 26px 26px;
  width: 100%;
  outline: none;
`;
