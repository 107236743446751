import styled from "styled-components";

interface AutocompleteContainerProps {
  border?: boolean;
  leftIcon?: boolean;
  highlighted?: boolean;
  small?: boolean;
}

interface LocProps {
  leftIcon?: boolean;
}

export const Error = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.error};
  color: ${({ theme: { colors } }): string => colors.white};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  border-radius: 8px;
  margin-top: -50px;
  opacity: 0.8;
  padding: 5px 15px;
  position: absolute;

  &:after {
    content:'';
    position: absolute;
    top: 100%;
    left: -80%;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 7px #e15915;
    border-left: solid 7px transparent;
    border-right: solid 7px transparent;
  }
`;

export const Cta = styled.button`
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  border: none;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  border-radius: 0 8px 8px 0;
  color: ${({ theme: { colors } }): string => colors.white};
  height: 48px;
  width: 40%;
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 30%;
  }
`;

export const Input = styled.input<AutocompleteContainerProps>`
  height: 48px;
  border: ${({ border, theme: { colors }, highlighted }): string =>
    border
      ? `${highlighted ? '2px' : '1px'} solid ${highlighted ? colors.secondary : colors.border}`
      : `none`};
  border-radius: 8px 0 0 8px;
  outline: ${({ highlighted }): string =>
    highlighted ? `none` : ''};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  padding-left: ${({ leftIcon }): number => leftIcon ? 25 : 15}px;
  padding-right: 34px;
  width: 100%;
`;

export const SmallInput = styled(Input)`
  border-radius: 10px;
  height: 40px;
  font-size: 14px;
  width: 100%;
`;

export const LocationInputContainer = styled.span<LocProps>`
  display: flex;
  margin-${({ leftIcon }): string => leftIcon ? "right" : "left"}: -25px;
  z-index: 1;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AutoLocationButton = styled.button`
  background: none;
  border: none;
  display: flex;
  margin: 0;
  padding: 0;
`;
