import { ChangeEvent, useCallback, useReducer } from "react";

export interface CheckboxState {
  valid: boolean;
  value: boolean;
}

export type TActions =
  | { type: "CHANGE"; value: boolean; valid: boolean }

export const initialCheckboxState = {
  valid: false,
  value: false
};

export function reducer(state: CheckboxState = initialCheckboxState, action: TActions): CheckboxState {
  switch (action.type) {
    case "CHANGE": {
      return {
        ...state,
        valid: action.valid,
        value: action.value
      };
    }
    default:
      return state;
  }
}

export type TSaveOnBlur = (args: { value: boolean }) => void | Promise<void>;

export type TIsValid = (value: boolean) => boolean;

export interface UseCheckboxParams {
  initialState?: Partial<CheckboxState>;
  saveOnBlur?: TSaveOnBlur;
  resetDuration?: number;
  isValid?: TIsValid;
}

export interface UseCheckboxResult extends CheckboxState {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function useCheckbox({
  initialState: partialInitialState,
  isValid
}: UseCheckboxParams = {}): UseCheckboxResult {
  const [
    { value, valid },
    dispatch
  ] = useReducer(reducer, {
    ...initialCheckboxState,
    ...partialInitialState
  });

  const onChange = useCallback(
    ({ target: { checked: targetValue } }: ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: "CHANGE",
        valid: isValid ? isValid(targetValue) : true,
        value: targetValue
      }),
    [dispatch, isValid]
  );

  return {
    onChange,
    valid,
    value,
  };
}
