import { FormattedMessage } from "react-intl";
import React, { FC } from "react";

import { AddressContainer, InformationBlock, InformationLine, InformationTitle } from "./style";

const Address: FC = () => (
  <InformationBlock>
    <InformationTitle>
      <FormattedMessage id="footer.address" />
    </InformationTitle>
    {[1, 2, 3, 4].map((i) => (
      <AddressContainer key={i}>
        <InformationLine>
          <FormattedMessage id={`footer.street.${i}`} />
        </InformationLine>
        <InformationLine>
          <FormattedMessage id={`footer.city.${i}`} />
        </InformationLine>
      </AddressContainer>
    ))}
  </InformationBlock>
);

export default Address;
