export interface Position {
  lat: number;
  lng: number;
}

export interface AddressComponents {
  number?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  country?: string;
}

export interface ExtendedGoogleMapsPlace {
  coordinates: Position;
  formattedAddress?: string;
  addressComponents?: AddressComponents;
}

export const PARIS_COORD: Position = {
  lat: 48.8534,
  lng: 2.3488,
};
