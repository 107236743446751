import React, { ComponentPropsWithRef } from "react";

import { Wrapper, Line } from "./style/loader";

function InputLoader(props: ComponentPropsWithRef<"div">): JSX.Element {
  return (
    <Wrapper {...props}>
      <Line />
    </Wrapper>
  );
}

export default InputLoader;
