import { FormattedMessage } from "react-intl";
import React, { FC } from "react";

import { InformationBlock, InformationLink, InformationExternalLink, InformationTitle } from "./style";
import getAppLink from '../../utils/getAppLink';

const Wast: FC = () => (
  <InformationBlock>
    <InformationTitle>
      <FormattedMessage id="general.wast.name" />
    </InformationTitle>
    <InformationExternalLink href="https://blog.wast.fr/" target="_blank">
      <FormattedMessage id="footer.blog" />
    </InformationExternalLink>
    <InformationLink to="/pricing">
      <FormattedMessage id="footer.pricing" />
    </InformationLink>
    <InformationExternalLink href={getAppLink()} target="_blank">
      <FormattedMessage id="footer.dl-app" />
    </InformationExternalLink>
  </InformationBlock>
);

export default Wast;
