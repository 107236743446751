import styled from "styled-components";

interface Props {
  valid?: boolean;
  focused?: boolean;
  disabled?: boolean;
}

const Wrapper = styled.div<Props>`
  background-color: ${({ theme: { colors }, disabled }): string =>
    disabled ? colors.disabled : colors.white};
  border: solid 1px
    ${({ valid, focused, theme: { colors } }): string => {
      if (!valid) return colors.error;
      return focused ? colors.secondary : "#fff";
    }};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 150ms;
  width: 100%;
`;

export default Wrapper;
