import { useLocation } from "@reach/router";
import React, { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import debounce from "debounce";
import { AppState } from "../../store";
import ButtonLink from "../../ui/button-link";
import ShoppingCart from "../../images/shopping-cart-primary.svg";
import { ShoppingCartState } from "../../store/shopping-cart/types";
import ProgressBar from "../progress-bar";
// import WastLogo from "../../images/wast-desktop-header-logo.svg";
import AddressAutocomplete from "../address-autocomplete";
import ConfirmAddressModal from "../confirm-address-modal";
import {
  ExtendedGoogleMapsPlace,
  PARIS_COORD,
} from "../../store/address/types";
import {
  CartMenu,
  CartNumber,
  DesktopHeaderContainer,
  DesktopMenuContainer,
  DesktopMenuLink,
  DesktopMenuLinkSmall,
  DesktopMenuText,
  DesktopMenuTextSmall,
  SignInContainer,
  WastLogoContainer,
} from "./style";
import PhoneCodeType from "../../types/phoneCodeType";
import { useIsDesktop } from "../../hooks/use-is-desktop";
import { setDefaultLanguage } from "../../store/user/actions";
import userLanguage from "../hooks/useLanguage";
import { WastLogo } from "../WastLogo";

interface DesktopMenuProps {
  menuState?: string | null;
  progressBar?: boolean;
  progressBarStep: number;
  activeHeader: boolean;
}

const DesktopMenu: FC<DesktopMenuProps> = ({
  menuState,
  progressBar,
  progressBarStep,
  activeHeader,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const token = useSelector<AppState, string>(state => state.auth.token);
  const firstName = useSelector<AppState, string | undefined>(
    state => state.user.firstName
  );
  const lastName = useSelector<AppState, string | undefined>(
    state => state.user.lastName
  );
  const { products } = useSelector<AppState, ShoppingCartState>(
    state => state.shoppingCart
  );
  const [isScrolledMenu, setScrolledMenu] = useState<boolean>(false);
  const [productsCount, setProductsCount] = useState<number>(0);
  const [isConfirmAddressModalOpen, openAddressModal] = useState<boolean>(
    false
  );
  const [address, setAddress] = useState<ExtendedGoogleMapsPlace>({
    coordinates: PARIS_COORD,
  });
  const location = useLocation();
  const isDesktop = useIsDesktop();

  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const count = products.reduce((add, product) => add + product.number, 0);
    setProductsCount(count);

    if (typeof window !== "undefined") {
      const setClassOnScroll = debounce((): void => {
        const supportPageOffset = window.pageXOffset !== undefined;
        const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
        const scroll = supportPageOffset
          ? window.pageYOffset
          : isCSS1Compat
          ? document.documentElement.scrollTop
          : document.body.scrollTop;

        if (scroll > 15) {
          setScrolledMenu(true);
        } else {
          setScrolledMenu(false);
        }
      }, 100);
      window.addEventListener("scroll", setClassOnScroll);

      return (): void => window.removeEventListener("scroll", setClassOnScroll);
    }
  }, [products, setProductsCount]);
  const userLang = userLanguage();

  const changeUserLang = useCallback((lang: string): void => {
    dispatch(setDefaultLanguage(lang));
  }, []);

  return (
    <>
      <ConfirmAddressModal
        open={isConfirmAddressModalOpen}
        closeModal={(): void => openAddressModal(false)}
        initialPlace={address}
      />
      <DesktopHeaderContainer
        scrolled={
          isScrolledMenu || (menuState !== null && menuState !== "home")
        }
        active={activeHeader}
        page={menuState}
      >
        <WastLogoContainer>
          <a href="/">
            <WastLogo />
          </a>
        </WastLogoContainer>
        {progressBar && isDesktop ? (
          <ProgressBar step={progressBarStep} />
        ) : (
          <DesktopMenuContainer>
            {isHomePage && isScrolledMenu ? (
              <AddressAutocomplete
                inputId="address-autocomplete"
                placeholder={intl.formatMessage({
                  id: "home.google.autocomplete.placeholder",
                })}
                onPlaceChange={(newAddress): void => {
                  setAddress(newAddress);
                  openAddressModal(true);
                }}
                border
                highlighted
                small
              />
            ) : null}
            <DesktopMenuLink href="/pricing">
              <DesktopMenuText active={"delivery" === menuState}>
                <FormattedMessage id="menu.delivery" />
              </DesktopMenuText>
            </DesktopMenuLink>
            <DesktopMenuLink href="/corporate">
              <DesktopMenuText active={"factory" === menuState}>
                <FormattedMessage id="menu.factory" />
              </DesktopMenuText>
            </DesktopMenuLink>
            <DesktopMenuLinkSmall href="/cart">
              <CartMenu>
                <DesktopMenuTextSmall active={"cart" === menuState}>
                  <ShoppingCart />
                </DesktopMenuTextSmall>
                {productsCount > 0 && (
                  <CartNumber>
                    <span>{productsCount}</span>
                  </CartNumber>
                )}
              </CartMenu>
            </DesktopMenuLinkSmall>
            {!token && (
              <DesktopMenuLink href={`/signin?view=${PhoneCodeType.SIGNUP}`}>
                <DesktopMenuText active={"signup" === menuState}>
                  <FormattedMessage id="menu.signup" />
                </DesktopMenuText>
              </DesktopMenuLink>
            )}
            <SignInContainer>
              <ButtonLink
                link={token ? "/profile" : "/signin"}
                text={
                  firstName && lastName
                    ? `${firstName} ${lastName}`
                    : intl.formatMessage({
                        id: token ? "profile.home.title" : "home.signin",
                      })
                }
                borderStyle="10px"
                width="100%"
                fontSize="15px"
              />
            </SignInContainer>
            {userLang === "fr" ? (
              <DesktopMenuLink
                href="#!"
                onClick={(): void => changeUserLang("en")}
              >
                <DesktopMenuText>EN</DesktopMenuText>
              </DesktopMenuLink>
            ) : (
              <DesktopMenuLink
                href="#!"
                onClick={(): void => changeUserLang("fr")}
              >
                <DesktopMenuText>FR</DesktopMenuText>
              </DesktopMenuLink>
            )}
          </DesktopMenuContainer>
        )}
      </DesktopHeaderContainer>
    </>
  );
};

export default DesktopMenu;
