import { createGlobalStyle } from "styled-components";

interface GlobalStyleProps {
  scrollbarWidth: number;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html.no-scroll {
    overflow: hidden;
    padding-right: ${({ scrollbarWidth }): number => scrollbarWidth}px;
  }

  body {
    font-family: Lato, sans-serif;
  }

  .pac-container {
    z-index: 600; //must be above modal's z index so the autocomplete results can be seen
  }
`;

export default GlobalStyle;
