import { useEffect, useState } from "react";

export function useWindowWidth(): number {
  if (typeof window !== 'undefined') {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = (): void => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return (): void => {
        window.removeEventListener("resize", handleResize);
      };
    });

    return width;
  }

  return 1000;
}
