import { useSelector } from "react-redux";
import { AppState } from "../store";

export function useIsAuth(): boolean {
  const auth = useSelector<AppState, string>(
    (state): string => state.auth.token
  );

  return auth !== "";
}
