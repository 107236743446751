import styled from "styled-components";

const Custom = styled.div`
  color: ${({ theme: { colors } }): string => colors.textLight};
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 10px;
  white-space: nowrap;

  svg {
    font-size: 16px;
    line-height: 10px;
    position: relative;
    top: 1px;
  }
`;

export const Left = styled(Custom)`
  margin-left: 8px;
`;

interface RightProps {
  valid?: boolean;
}

export const Right = styled(Custom)<RightProps>`
  margin-right: 8px;
  color: ${({ valid, theme: { colors } }): string =>
    valid ? colors.textLight : colors.error};
`;

export const Placeholder = styled(Right)`
  font-size: 10px;
`;
