import styled from 'styled-components';

export const PlusContainer = styled.button`
  background-color: ${({ theme: { colors } }): string => colors.white};
  border: none;
  display: flex;
  cursor: pointer;
  width: 35px;
`;

export const LessContainer = styled(PlusContainer)`
  cursor: auto;
`

export const ProductName = styled.div`
  display: flex;
`;

export const BundleProductName = styled.div`
  display: flex;
  font-weight: bold;
`;

export const Price = styled.span`
  font-weight: bold;
  margin-right: 2em;
  width: 75px;
  text-align: right;
`;

export const ProductListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 60%;
  }
`;

export const ProductLineContainer = styled.div`
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.white};
  border: none;
  display: flex;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  height: 50px;
  justify-content: space-between;
  margin: 0.5em 0;
  padding: 0;
  width: 100%;
  border-radius: 10px;
`

export const QuantityContainer = styled.div`
  width: 50px;
  padding-left: 10px;
`

export const Number = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme: { colors } }): string => colors.secondary};
`

export const RightContainer = styled.div`
  display: flex;
`;
