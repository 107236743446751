import styled from 'styled-components';
import Close from "../../images/WhiteClose.svg";

export const HomeCross = styled(Close)`
  margin: auto;
`;

export const OverlayWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme: { colors } }): string => colors.primary};
  color: ${({ theme: { colors } }): string => colors.white};
  z-index: 3;
`;

export const Logos = styled.div`
  margin: auto;
  width: 40%;
`;

export const Centered = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  text-align: center;
`;

export const CenteredButtons = styled(Centered)`
  margin: auto;
`;
