import styled from "styled-components";

interface ScrolledProps {
  scrolled?: boolean;
}

interface ActiveProps {
  active?: boolean;
}

interface ActiveHeaderProps {
  active: boolean;
  page: string | null | undefined;
}

export const WastLogoContainer = styled.div`
  display: none;
  height: 100%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: block;
    margin: 20px 5% 20px 0;
    min-width: 150px;
  }
`;

export const SignInContainer = styled.div`
  display: none;
  justify-content: right;
  margin-left: auto;
  text-align: center;
  min-width: 20%;
  min-height: 40px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: flex;
  }
`;

export const DesktopHeaderContainer = styled.div<ScrolledProps & ActiveHeaderProps>`
  align-items: center;
  background-color: ${({ scrolled }): string => (scrolled ? "#fff" : "none")};
  transition: background-color 0.5s ease;
  display: flex;
  justify-content: space-between;
  padding-left: 220px;
  padding-right: 15%;
  position: ${({ active, page }): string => (!active && (page === "order" || page === "cart") ? "relative" : "fixed")};
  top: 0;
  width: 100%;
  z-index: 200;
`;

export const DesktopMenuContainer = styled.div`
  align-items: center;
  display: none;
  height: 80px;
  margin: 0;
  position: relative;
  width: 85%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: flex;
  }
`;

export const DesktopMenuLink = styled.a`
  color: ${({ theme: { colors } }): string => colors.primary};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  position: relative;
  width: 30%;
`;

export const DesktopMenuLinkSmall = styled.a`
color: ${({ theme: { colors } }): string => colors.primary};
font-family: ${({ theme: { fonts } }): string => fonts.main};
font-size: 14px;
font-weight: bold;
text-align: center;
text-decoration: none;
position: relative;
width: 15%;
`;

export const DesktopMenuText = styled.div<ActiveProps>`
  color: ${({ active, theme: { colors } }): string =>
    active ? colors.secondary : colors.primary};

  ${({ active, theme: { colors } }): string =>
    active
      ? `
  ::after {
    content: ".";
    display: block;
    height: 6px;
    width: 6px;
    background-color: ${colors.secondary};
    border-radius: 50%;
    position: absolute;
    top: 33px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }
  `
      : ""}
`;

export const DesktopMenuTextSmall = styled.div<ActiveProps>`
  color: ${({ active, theme: { colors } }): string =>
    active ? colors.secondary : colors.primary};

  ${({ active, theme: { colors } }): string =>
    active
      ? `
  ::after {
    content: ".";
    display: block;
    height: 6px;
    width: 6px;
    background-color: ${colors.secondary};
    border-radius: 50%;
    position: absolute;
    top: 33px;
    left: 12px;
    transform: translateX(-50%);
    overflow: hidden;
  }
  `
      : ""}
`;

export const CartMenu = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const CartNumber = styled.div`
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  border-radius: 50%;
  color: ${({ theme: { colors } }): string => colors.white};
  display: flex;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 9px;
  height: 18px;
  line-height: 1em;
  justify-content: center;
  position: relative;
  right: 0.8em;
  top: 0.8em;
  width: 18px;
`;
