import { FormattedMessage } from "react-intl";
import React, { FC } from "react";

import { InformationBlock, InformationLink, InformationTitle } from "./style";
import { Edge } from '../../../types/contentful';

interface ServicesProps {
  services: Edge[];
  type: string;
}

const Services: FC<ServicesProps> = ({ type, services }) => (
  <InformationBlock>
    <InformationTitle>
      {(type === "services") ? <FormattedMessage id="footer.our-services" /> :
        (type === "pressingNord") ? <FormattedMessage id="footer.pressing.north" /> :
          (type === "pressingSud") ? <FormattedMessage id="footer.pressing.south" /> : <FormattedMessage id="footer.wastparis" />}
    </InformationTitle>
    {services.map(({ node: s }) => (
      <InformationLink to={`/${s.slug}`} key={s.slug}>
        {s.title}
      </InformationLink>
    ))}
  </InformationBlock>
);

export default Services;
