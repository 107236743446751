import styled from "styled-components";
import { Header, Content } from "../../modal/style";

export const AutocompleteContainer = styled.div`
  left: 0;
  margin-left: 5%;
  margin-top: 20px;
  position: absolute;
  width: 90%;
  z-index: 900;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    margin-left: 25%;
    top: 20px;
    margin-top: 0;
    width: 50%;
  }
`;

export const InputText = styled.span`
  font-family: ${({ theme }): string => theme.fonts.main};
  color: ${({ theme }): string => theme.colors.dark};
  margin: 36px 0;
  font-size: 15px;
  font-weight: bold;
`;

export const Button = styled.button`
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  border: none;
  border-radius: 57px;
  color: ${({ theme: { colors } }): string => colors.white};
  display: flex;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  justify-content: center;
  height: 48px;
  width: 90%;

  &:hover {
    cursor: pointer;
  }

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 286px;
  }
`;

export const ButtonWrapper = styled.div`
  bottom: 25px;
  display: flex;
  justify-content: center;
  position: absolute;
  transform: translateY(-100%);
  width: 100%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    transform: translateY(0);
  }

  @media (max-width: ${({ theme: { breakpoints } }): string => breakpoints.tabletValue}px) {
    bottom: 70px;
  }

  @media (max-width: ${({ theme: { breakpoints } }): string => breakpoints.phoneValue}px) {
    bottom: 50px;
  }
`;

export const GoogleMapReactWrapper = styled.div`
  height: 100%;
  position: relative;

  .gm-style-pbc {
    z-index: 9999999 !important;
  }

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    height: 100;
    bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const ConfirmAddressContent = styled(Content)`
  height: 100%;
  padding-bottom: 53px;
  position: relative;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    height: 600px;
    padding-bottom: 0px;
  }
`;

export const ConfirmAddressHeader = styled(Header)`
  color: ${({ theme }): string => theme.colors.lightGrey};
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const SelectContainer = styled.div`
  width: 90%;
  z-index: 3000;

  @media ${({ theme: { breakpoints } }): string => breakpoints.tablet} {
    width: 60%;
  }
`;

export const NoteContainer = styled.div`
  width: 90%;
  z-index: 2000;

  @media ${({ theme: { breakpoints } }): string => breakpoints.tablet} {
    width: 60%;
  }
`;
