import { HttpResponse, get, post } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";

import {
  SET_ORDERS,
  UPDATE_ORDER,
  OrdersActionTypes,
  Response,
  OrdersResponse,
} from "./types";
import { Order } from "../../types/order";
import { AppState } from "..";

export const setOrders = (orders: Order[]): OrdersActionTypes => ({
  type: SET_ORDERS,
  orders,
});

export const updateOrder = (order: Order): OrdersActionTypes => ({
  type: UPDATE_ORDER,
  order,
});

export const getOrders = (page: number) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<Response> => {
  let response!: HttpResponse<OrdersResponse>;

  try {
    response = await get(`/orders?page=${page}`, getState());
  } catch (e) {
    return {
      success: false,
      error: "Error while fetching",
    };
  }

  if (response && response.parsedBody) {
    const { orders } = response.parsedBody;

    dispatch(setOrders(orders));
  }

  return {
    success: true,
  };
};

export interface GetSingleOrderResponse {
  success: boolean;
  order?: Order;
  error?: string;
}

export const getSingleOrder = (id: string) => async (
  _: ReduxDispatch,
  getState: () => AppState
): Promise<GetSingleOrderResponse> => {
  let response!: HttpResponse<Order>;

  try {
    response = await get(`/orders/${id}`, getState());
  } catch (e) {
    switch (e.status) {
      case 404:
        return {
          success: false,
          error: "order.not.found",
        };
      case 500:
        return {
          success: false,
          error: "error.server",
        };
      default:
        return {
          success: false,
          error: "error.unknown",
        };
    }
  }

  if (response && response.parsedBody) {
    const order = response.parsedBody;

    return {
      success: true,
      order,
    };
  }

  return {
    success: false,
    error: "error.unknown",
  };
};

export const cancelOrder = (order: Order) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<Response> => {
  let response!: HttpResponse<Order>;

  try {
    response = await post(`/orders/${order.id}/cancel`, undefined, getState());
  } catch (e) {
    console.error('error', e)
    return {
      success: false,
      error: e && e.error && e.error.message ? e.error.message : 'Error while canceling',
    };
  }

  if (response && response.parsedBody) {
    dispatch(updateOrder(response.parsedBody));
  }

  return {
    success: true,
  };
}

export const rescheduleOrderRequest = (order: Order) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState,
): Promise<Response> => {
  let response!: HttpResponse<Order>;

  const state = getState();

  const {
    pickupRange,
    dropOffRange,
  } = state.shoppingCart;

  let body = {}

  if (pickupRange)
    body = { pickupTime: {
      startTime: pickupRange.startDate.toISOString(),
      endTime: pickupRange.endDate.toISOString()
    } }
  if (dropOffRange)
    body = { ...body, dropoffTime: { 
      startTime: dropOffRange.startDate.toISOString(),
      endTime: dropOffRange.endDate.toISOString() 
    }}

  try {
    response = await post(`/orders/${order.id}/reschedule`, body, getState());
  } catch (e) {
    console.error('error', e)
    return {
      success: false,
      error: e && e.error && e.error.message ? e.error.message : 'Error while rescheduling',
    };
  }

  if (response && response.parsedBody) {
    dispatch(updateOrder(response.parsedBody));
  }

  return {
    success: true,
  };
};
