import styled from "styled-components";
import { Link } from "gatsby";

export const AddressContainer = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }): string => colors.lightGrey};
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;
export const Column = styled.div``;

export const Copyright = styled.div`
  font-size: 12px;
  text-align: center;
`;

export const InformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const InformationGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    flex-direction: row;
  }
`;

export const InformationLine = styled.span`
  color: inherit;
  font-size: 12px;
`;

export const InformationLink = styled(Link)`
  color: inherit;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const InformationExternalLink = styled.a`
  color: inherit;
  font-size: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const InformationTitle = styled.h5`
  color: ${({ theme }): string => theme.colors.secondary};
  font-family: inherit;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 50px;
  width: 141px;
`;

export const Title = styled.h2`
  color: inherit;
  font-family: inherit;
  font-size: 26px;
  margin-bottom: 25px;
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }): string => theme.colors.primary};
  color: ${({ theme }): string => theme.colors.white};
  font-family: ${({ theme }): string => theme.fonts.main};
  padding: 74px 12.5% 230px;
  width: 100%;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    padding-bottom: 18px;
  }
`;

export const HeadWrapper = styled.div`
  align-items: center;
  border-top: 1px solid ${({ theme }): string => theme.colors.lightGrey};
  color: ${({ theme }): string => theme.colors.primary};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: ${({ theme }): string => theme.fonts.main};
  justify-content: space-around;
  padding: 20px 0;
  width: 100%;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    flex-direction: row;
    padding: 24px 20% 18px;
  }
`;

export const Contacts = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  width: 400px;

  @media ${({ theme }): string => theme.breakpoints.desktop} {
    flex-direction: row;
  }
`;

export const ContactItem = styled.div`
  display: flex;
  justify-content: space-around;
  width: 180px;
`;

export const Timetable = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WastLogoContainer = styled.div`
  display: none;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: block;
    min-width: 150px;
  }
`;
