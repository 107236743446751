import React, { FC } from "react";
import { useSelector } from "react-redux";

import ProductLine from "../pricing/product-line";
import {
  ProductsContainer,
} from "./style/products-in-cart";

import { AppState } from "../../store";
import { ProductInCart } from "../../store/shopping-cart/types";

const ProductsInCart: FC = () => {
  const products = useSelector<AppState, ProductInCart[]>(
    state => state.shoppingCart.products
  );

  return (
    <>
      {products.length > 0 ? (
        <ProductsContainer>
          {products.map(
            ({ product, number }: ProductInCart) => (
              <ProductLine key={product.id} product={product} number={number} />
            )
          )}
        </ProductsContainer>
      ) : null}
    </>
  );
};

export default ProductsInCart;
