import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { Product } from "../../types/product";
import {
  PlusContainer,
  Price,
  ProductLineContainer,
  ProductName,
  BundleProductName,
  Number,
  QuantityContainer,
  LessContainer,
  RightContainer,
} from "./style/productList";
import Plus from "../../images/plus.svg";
import Less from "../../images/less.svg";
import { addProduct, removeProduct } from "../../store/shopping-cart/actions";
import { ShoppingCartActionTypes } from "../../store/shopping-cart/types";
import twoDecimals from "../../utils/twoDecimals";

interface ProductButtonProps {
  product: Product;
  number: number;
}

const ProductLine: FC<ProductButtonProps> = ({ product, number }) => {
  const dispatch = useDispatch();

  const handleRemoveOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, product: Product): void => {
    e.stopPropagation();
    dispatch(removeProduct(product));
  }

  return (
    <ProductLineContainer key={`category-${product.id}`} onClick={(): ShoppingCartActionTypes => dispatch(addProduct(product))}>
      {(product.type === "BUNDLE") ?
        <BundleProductName>
          <QuantityContainer>
            {number ? <Number>{`x${number}`}</Number> : null}
          </QuantityContainer>
          {product.name}
        </BundleProductName> :
        <ProductName>
        <QuantityContainer>
          {number ? <Number>{`x${number}`}</Number> : null}
        </QuantityContainer>
        {product.name}
      </ProductName>}
      <RightContainer>
        {number > 0 ? (
          <PlusContainer
            onClick={(e): void =>
              handleRemoveOnClick(e, product)
            }
          >
            <Less />
          </PlusContainer>
        ) : (
          <LessContainer />
        )}
        <PlusContainer>
          <Plus />
        </PlusContainer>
        <Price>{twoDecimals(product.price/100)} €</Price>
      </RightContainer>
    </ProductLineContainer>
  );
};

export default ProductLine;
