import { FormattedMessage } from "react-intl";
import React, { FC } from "react";

import { InformationBlock, InformationLink, InformationTitle } from "./style";

interface Pressing {
  url: string;
  name: string;
}

const PRESSINGS = {
  1: { url: "/pressing/paris-1", name: "Paris 1" },
  2: { url: "/pressing/paris-2", name: "Paris 2" },
  3: { url: "/pressing/paris-3", name: "Paris 3" },
  4: { url: "/pressing/paris-4", name: "Paris 4" },
  5: { url: "/pressing/paris-5", name: "Paris 5" },
  6: { url: "/pressing/paris-6", name: "Paris 6" },
  7: { url: "/pressing/paris-7", name: "Paris 7" },
  8: { url: "/pressing/paris-8", name: "Paris 8" },
  9: { url: "/pressing/paris-9", name: "Paris 9" },
  10: { url: "/pressing/paris-10", name: "Paris 10 " },
  11: { url: "/pressing/paris-11", name: "Paris 11" },
  12: { url: "/pressing/paris-12", name: "Paris 12" },
  13: { url: "/pressing/paris-13", name: "Paris 13" },
  14: { url: "/pressing/paris-14", name: "Paris 14" },
  15: { url: "/pressing/paris-15", name: "Paris 15" },
  16: { url: "/pressing/paris-16", name: "Paris 16" },
  17: { url: "/pressing/paris-17", name: "Paris 17" },
  18: { url: "/pressing/paris-18", name: "Paris 18" },
  19: { url: "/pressing/paris-19", name: "Paris 19" },
  20: { url: "/pressing/paris-20", name: "Paris 20" },
  clichy: { url: "/pressing/clichy-la-garenne", name: "Clichy-la-Garenne" },
  levallois: { url: "/pressing/levallois-perret", name: "Levallois-Perret" },
  neuilly: { url: "/pressing/neuilly-sur-seine", name: "Neuilly-sur-Seine" },
  boulogne: {
    url: "/pressing/boulogne-billancourt",
    name: "Boulogne-Billancourt",
  },
  issy: { url: "/pressing/issy-les-moulineaux", name: "Issy-les-Moulineaux" },
  mande: { url: "/pressing/saint-mande", name: "Saint-Mandé" },
  puteaux: { url: "/pressing/puteaux", name: "Puteaux" },
  asnieres: { url: "/pressing/asnieres-sur-seine", name: "Asnières-sur-Seine" },
  courbevoie: { url: "/pressing/courbevoie", name: "Courbevoie" },
  vanves: { url: "/pressing/vanves", name: "Vanves" },
  malakoff: { url: "/pressing/malakoff", name: "Malakoff" },
  montrouge: { url: "/pressing/montrouge", name: "Montrouge" },
};

const Pressings: FC = () => (
  <InformationBlock>
    <InformationTitle>
      <FormattedMessage id="footer.pressings" />
    </InformationTitle>
    {Object.values(PRESSINGS).map((p: Pressing) => (
      <InformationLink to={`${p.url}`} key={p.url}>
        {p.name}
      </InformationLink>
    ))}
  </InformationBlock>
);

export default Pressings;
