import { FormattedMessage } from "react-intl";
import React, { FC } from "react";

import Facebook from "../images/social-facebook";
import Twitter from "../../images/social-twitter.svg";
import InstagramIcon from "../images/social-instagram";

import {
  InformationBlock,
  InformationExternalLink,
  InformationTitle,
} from "./style";
import { InstagramBackground, Social, ShortSocialWrapper, SocialWrapper } from "./style/socials";

const Instagram: FC = () => (
  <InstagramBackground>
    <InstagramIcon />
  </InstagramBackground>
);

const Socials: FC = () => (
  <InformationBlock>
    <InformationTitle>
      <FormattedMessage id="footer.socials" />
    </InformationTitle>
    <SocialWrapper>
      <InformationExternalLink target="_blank" href="https://fr-fr.facebook.com/wast.france/">
        <Social>
          <Facebook />
        </Social>
      </InformationExternalLink>
      <InformationExternalLink target="_blank" href="https://twitter.com/wast_fr">
        <Social>
          <Twitter />
        </Social>
      </InformationExternalLink>
      <InformationExternalLink target="_blank" href="https://www.instagram.com/wast.france/">
        <Social>
          <Instagram />
        </Social>
      </InformationExternalLink>
    </SocialWrapper>
  </InformationBlock>
);

export default Socials;

export const ShortSocials: FC = () => (
  <ShortSocialWrapper>
    <InformationExternalLink target="_blank" href="https://fr-fr.facebook.com/wast.france/">
      <Social>
        <Facebook />
      </Social>
    </InformationExternalLink>
    <InformationExternalLink target="_blank" href="https://twitter.com/wast_fr">
      <Social>
        <Twitter />
      </Social>
    </InformationExternalLink>
    <InformationExternalLink target="_blank" href="https://www.instagram.com/wast.france/">
      <Social>
        <Instagram />
      </Social>
    </InformationExternalLink>
  </ShortSocialWrapper>
);
