import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import dayjs from "dayjs";
import { get, HttpResponse, post } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import { ExtendedGoogleMapsPlace } from '../address/types';

import {
  ADD_PRODUCT,
  SET_PRODUCTS,
  REMOVE_DISCOUNT_CODE,
  REMOVE_PRODUCT,
  SET_CURRENT_OPTION,
  SET_DISCOUNT_CODE,
  SET_DROP_OFF_RANGE,
  SET_OPTIONS,
  SET_PAYMENT_METHOD,
  SET_PICKUP_RANGE,
  RESET_CART,
  RESET_TIMESLOT,
  ShoppingCartActionTypes,
  SET_ADDRESS_MANUALLY_ADDED,
  SET_ADDRESS,
} from "./types";
import { AppState } from "..";
import { Product } from "../../types/product";
import { DiscountCode } from "../../types/discount";
import { Range } from "../../types/range";
import { Option } from "../../types/option";
import { PaymentMethod } from "../../types/payment-method";

interface GetOptionsResponse {
  serviceClasses: Option[];
}

export const addProduct = (product: Product): ShoppingCartActionTypes => ({
  type: ADD_PRODUCT,
  product,
});

export const setProducts = (products: Product[]): ShoppingCartActionTypes => ({
  type: SET_PRODUCTS,
  products,
});

export const removeProduct = (product: Product): ShoppingCartActionTypes => ({
  type: REMOVE_PRODUCT,
  product,
});

export const setDiscountCode = (
  discountCode: DiscountCode
): ShoppingCartActionTypes => ({
  type: SET_DISCOUNT_CODE,
  discountCode,
});

export const removeDiscountCode = (): ShoppingCartActionTypes => ({
  type: REMOVE_DISCOUNT_CODE,
});

export const setPickupRange = (
  pickupRange: Range
): ShoppingCartActionTypes => ({
  type: SET_PICKUP_RANGE,
  pickupRange,
});

export const setDropOffRange = (
  dropOffRange: Range
): ShoppingCartActionTypes => ({
  type: SET_DROP_OFF_RANGE,
  dropOffRange,
});

export const setOptions = (options: Option[]): ShoppingCartActionTypes => ({
  type: SET_OPTIONS,
  options,
});

export const setCurrentOption = (
  currentOption: Option
): ShoppingCartActionTypes => ({
  type: SET_CURRENT_OPTION,
  currentOption,
});

export const setPaymentMethod = (
  paymentMethod: PaymentMethod
): ShoppingCartActionTypes => ({
  type: SET_PAYMENT_METHOD,
  paymentMethod,
});

export const resetCart = (): ShoppingCartActionTypes => ({
  type: RESET_CART,
});

export const resetTimeslot = (): ShoppingCartActionTypes => ({
  type: RESET_TIMESLOT,
});

export const setAddressManuallyAdded = (value: boolean): ShoppingCartActionTypes => ({
  type: SET_ADDRESS_MANUALLY_ADDED,
  value,
});

export const setCartAddress = (address: ExtendedGoogleMapsPlace | undefined): ShoppingCartActionTypes => ({
  type: SET_ADDRESS,
  address,
});

export const sendDiscountCode = (
  discountCode: string
): ThunkAction<
  Promise<HttpResponse<DiscountCode>>,
  AppState,
  {},
  AnyAction
> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<DiscountCode>> => {
    try {
      const response = await post<DiscountCode>(
        "/discounts",
        { discountCode },
        getState()
      );

      if (response.parsedBody) {
        dispatch(setDiscountCode(response.parsedBody));
      }

      return response;
    } catch (error) {
      return error;
    }
  };

export const getOptions = (): ThunkAction<
  Promise<HttpResponse<GetOptionsResponse>>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState): Promise<HttpResponse<GetOptionsResponse>> => {
  try {
    const response = await get<GetOptionsResponse>(
      "/service-classes",
      getState()
    );

    if (response.parsedBody) {
      const { serviceClasses } = response.parsedBody;
      serviceClasses.sort((a: Option, b: Option) =>
        a.sortOrder < b.sortOrder ? -1 : 1
      );

      dispatch(setOptions(serviceClasses));
      dispatch(setCurrentOption(serviceClasses[0]));
    }

    return response;
  } catch (error) {
    return error;
  }
};

export const sendOrder = (
  notes?: string,
  optionalAddressNote?: string,
): ThunkAction<Promise<HttpResponse<{}>>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState
): Promise<HttpResponse<{}>> => {
    try {
      const state = getState();

      const {
        address,
        products,
        pickupRange,
        dropOffRange,
        currentOption,
        discountCode,
        paymentMethod,
        addressManuallyAdded,
        totalPrice,
      } = state.shoppingCart;

      const savedAddress = state.user.addresses[0];

      if (
        address &&
        address.addressComponents &&
        pickupRange &&
        dropOffRange &&
        currentOption &&
        paymentMethod
      ) {
        const addrComponents = address.addressComponents;
        const parsedProducts = products.map(product => (`${product.number} ${product.product.name}`)).join(', ')
        if (notes && products.length > 0)
          notes = `${notes}\nInitialement prévu: ${parsedProducts}`
        else if (products.length > 0)
          notes = `Initialement prévu: ${parsedProducts}`
        const addressNotes = !addressManuallyAdded ? (savedAddress.notes && savedAddress.notes.length > 0 ? savedAddress.notes : "Aucune information") :
          (optionalAddressNote && optionalAddressNote.length > 0 ? optionalAddressNote : "Aucune information")
        const response = await post<{}>(
          "/orders",
          {
            pickupAddress: {
              number: addrComponents.number ? addrComponents.number : "",
              street: addrComponents.street ? addrComponents.street : "",
              city: addrComponents.city ? addrComponents.city : "",
              country: addrComponents.country ? addrComponents.country : "",
              notes: addressNotes,
            },
            dropoffAddress: {
              number: addrComponents.number ? addrComponents.number : "",
              street: addrComponents.street ? addrComponents.street : "",
              city: addrComponents.city ? addrComponents.city : "",
              country: addrComponents.country ? addrComponents.country : "",
              notes: addressNotes,
            },
            paymentMethodId: paymentMethod.id,
            // we recast dates to dayJS because they are sometimes string, sometimes dates
            // not sure why... persistore ?
            pickupTime: {
              startTime: dayjs(pickupRange.startDate).toISOString(),
              endTime: dayjs(pickupRange.endDate).toISOString(),
            },
            dropoffTime: {
              startTime: dayjs(dropOffRange.startDate).toISOString(),
              endTime: dayjs(dropOffRange.endDate).toISOString(),
            },
            serviceClass: currentOption.id,
            ...(discountCode && { discountCode: discountCode.code }),
            ...(notes && { notes }),
            estimate: totalPrice,
            products: products.map(product => ({
              product: product.product,
              quantity: product.number
            }))
          },
          state
        );

        if (response.parsedBody) {
          dispatch(resetCart());
        }

        return response;
      }
      throw new Error("Fields are not valid");
    } catch (error) {
      console.error(error);
      return error;
    }
  };
