import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  AutoLocationButton,
  Cta,
  Error,
  Input,
  InputContainer,
  LocationInputContainer,
  SmallInput,
} from "./style";
import { Input as InputFooter } from "../home/area-served/style";
import Location from "../../images/Location.svg";
import Script from "react-load-script";
import {
  ExtendedGoogleMapsPlace,
  PARIS_COORD,
  Position,
} from "../../store/address/types";
import { getAddressFromCoordinates } from "../../store/address/actions";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const renderInput = (small: boolean, {
  border,
  defaultValue,
  highlighted,
  inputId,
  leftIcon,
  onChange,
  placeholder,
  value,
}): JSX.Element => small
  ? (
    <SmallInput
      border={border}
      defaultValue={defaultValue}
      highlighted={highlighted}
      id={inputId}
      leftIcon={leftIcon}
      onChange={onChange}
      placeholder={placeholder}
      small={small}
      value={value ? value : undefined}
    />
  ) : (
    <Input
      border={border}
      defaultValue={defaultValue}
      highlighted={highlighted}
      id={inputId}
      leftIcon={leftIcon}
      onChange={onChange}
      placeholder={placeholder}
      small={small}
      value={value ? value : undefined}
    />
  )

interface AddressAutocompleteProps {
  inputId: string;
  border?: boolean;
  initialValue?: ExtendedGoogleMapsPlace;
  placeholder?: string;
  onPlaceChange: (newPlace: ExtendedGoogleMapsPlace) => void;
  leftIcon?: boolean;
  cta?: boolean;
  highlighted?: boolean;
  small?: boolean;
}

const AddressAutocomplete = ({
  inputId,
  placeholder = "",
  onPlaceChange,
  initialValue = undefined,
  border,
  leftIcon = false,
  cta = false,
  highlighted = false,
  small = false,
}: AddressAutocompleteProps): JSX.Element => {
  const [inputAddressValue, setInputAddressValue] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const requireField = useCallback(() => setShowError(true), [setShowError]);
  let autocomplete!: google.maps.places.Autocomplete;
  const [confirmedAddress, setConfirmedAddress] = useState<
    Position | undefined
  >(initialValue?.coordinates);
  const token = useSelector<AppState, string>(state => state.auth.token);

  const handlePlaceSelect = useCallback(() => {
    if (autocomplete) {
      const addressObject = autocomplete.getPlace();
      const coordinates =
        addressObject &&
        addressObject.geometry &&
        addressObject.geometry.location
          ? {
              lat: addressObject.geometry.location.lat(),
              lng: addressObject.geometry.location.lng(),
            }
          : PARIS_COORD;

      if (addressObject.address_components) {
        let number = "";
        let street = "";
        let city = "";
        let postalCode = "";
        let country = "";
        addressObject.address_components.forEach(component => {
          if (component.types.includes("street_number")) {
            number = component.long_name;
          } else if (component.types.includes("route")) {
            street = component.long_name;
          } else if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            postalCode = component.long_name;
          }
        });

        onPlaceChange({
          coordinates,
          formattedAddress: addressObject.formatted_address,
          addressComponents: {
            number: number,
            street: street,
            city: city,
            country: country,
            postalCode: postalCode,
          },
        });
      }
    }
  }, [autocomplete]);

  const initAutocomplete = (): void => {
    if (typeof window === "undefined") {
      return;
    }
    const options = { componentRestrictions: { country: ["FR"] } };
    const autocompleteId = document.getElementById(inputId);

    if (autocompleteId) {
      autocomplete = new google.maps.places.Autocomplete(
        autocompleteId as HTMLInputElement,
        options
      );

      if (autocomplete !== null) {
        autocomplete.setFields([
          "address_components",
          "formatted_address",
          "geometry",
        ]);
        autocomplete.addListener("place_changed", handlePlaceSelect);
      }
    }
  };

  const getGeolocation = (): void => {
    if (typeof window === "undefined") {
      return;
    }
    if (window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const coordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        getAddressFromCoordinates(coordinates, result => {
          if (result.success) {
            onPlaceChange({
              coordinates,
              formattedAddress:
                result.address && result.address.formatted_address,
            });
          }
        });
      });
    }
  };

  const onConfirmAddress = async (): Promise<void> => {
    // if confirmed address is not the original value 
    if (confirmedAddress && confirmedAddress !== PARIS_COORD) {
      navigate(token ? "/order" : "/cart");
    } else {
      requireField();
    }
  };

  useEffect(() => {
    setInputAddressValue(initialValue?.formattedAddress ?? "");
    setConfirmedAddress(initialValue?.coordinates);
  }, [initialValue]);

  const defaultValue = initialValue?.formattedAddress;

  return (
    <InputContainer>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places,geometry`}
        onLoad={initAutocomplete}
      />
      {leftIcon ? (
        <LocationInputContainer leftIcon={leftIcon}>
          <AutoLocationButton onClick={(): void => getGeolocation()}>
            <Location />
          </AutoLocationButton>
        </LocationInputContainer>
      ) : null}
      {showError ? (
        <Error>
          <FormattedMessage id="home.required.field" />
        </Error>
      ) : null}
      {inputId == "address-autocomplete-footer" ? (
        <InputFooter
          id={inputId}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
       ) :
        renderInput(small, {
          border,
          defaultValue,
          highlighted,
          inputId,
          leftIcon,
          onChange: (event): void => {
            setInputAddressValue(event.target.value);
            setConfirmedAddress(undefined)
          },
          placeholder,
          value: inputAddressValue,
        })
      }
      {cta ? (
        <Cta onClick={onConfirmAddress}>
          <FormattedMessage id={"home.order"} />
        </Cta>
      ) : null}
      {leftIcon ? null : (
        <LocationInputContainer>
          <AutoLocationButton onClick={(): void => getGeolocation()}>
            <Location/>
          </AutoLocationButton>
        </LocationInputContainer>
      )}
    </InputContainer>
  );
};

export default AddressAutocomplete;
