import styled from "styled-components";

interface WidgetProps {
  fromMenu: boolean;
};

export const ProductsContainer = styled.div`
  display: none;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: flex;
    align-items: start;
    border-radius: 10px;
    display: flex;
    position: absolute;
    right: 0;
    top: 300px;
    width: 35%;
  }
`;

export const CartWidgetContainer = styled.div<WidgetProps>`
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  bottom: 80px;
  color: ${({ theme: { colors } }): string => colors.white};
  display: flex;
  flex-wrap: wrap;
  height: 140px;
  justify-content: space-between;
  margin: 0;
  padding: 20px;
  position: fixed;
  width: 100%;
  z-index: 2;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    ${({ fromMenu }): string => fromMenu ? `
      display: none;
    ` : `
      align-items: start;
      border-radius: 10px;
      display: flex;
      height: 240px;
      padding: 20px 20px 0 20px;
      position: absolute;
      right: 0;
      top: 60px;
      width: 35%;
    `}
  }
`;

export const Price = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main };

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    font-size: 27px;
    font-weight: bold;
    text-align: right;
    width: 50%;
  }
`;

export const ProductText = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main };

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    font-weight: bold;
    width: 50%;
  }
`;

export const LegalText = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  color: ${({ theme: { colors } }): string => colors.lightBlue};
  font-size: 9px;
  line-height: 12px;
  width: 100%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    border-top: solid 2px rgba(255, 255, 255, 0.5);
    padding: 20px 0 10px 0;
    font-size: 12px;
  }
`;

export const TaxText = styled.span`
  color: ${({ theme: { colors } }): string => colors.lightBlue};
  font-size: 10px;
  margin-left: 10px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    color: ${({ theme: { colors } }): string => colors.white};
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-left: 0;
    margin-top: -10px;
    width: 100%;
  }
`;

export const OrderButton = styled.button`
  background-color: ${({ theme: { colors } }): string => colors.white};
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  color: ${({ theme: { colors } }): string => colors.secondary};
  height: 35px;
  margin: 10px 0;
  width: 100%;
  cursor: pointer;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 16px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    border-radius: 25px;
    height: 50px;
  }
`;
