import { FormattedMessage } from "react-intl";
import React, { FC } from "react";

import { InformationBlock, InformationLink, InformationExternalLink, InformationTitle } from "./style";

const Information: FC = () => (
  <InformationBlock>
    <InformationTitle>
      <FormattedMessage id="footer.info" />
    </InformationTitle>
    <InformationExternalLink target="_blank" href="https://support.wast.fr">
      <FormattedMessage id="footer.faq" />
    </InformationExternalLink>
    <InformationLink to="/terms">
      <FormattedMessage id="footer.terms" />
    </InformationLink>
  </InformationBlock>
);

export default Information;
