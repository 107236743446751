import React, { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";

import {
  CartWidgetContainer,
  LegalText,
  OrderButton,
  Price,
  ProductText,
  TaxText,
  ProductsContainer,
} from "./style/cart-widget";
import twoDecimals from "../../utils/twoDecimals";
import { AppState } from "../../store";
import { ShoppingCartState } from "../../store/shopping-cart/types";
import ProductList from "./products-in-cart";
import userLanguage from "../hooks/useLanguage";

interface CartProps {
  cart?: boolean;
  fromMenu: boolean;
}

const CartWidget: FC<CartProps> = ({ cart, fromMenu = true }) => {
  if (!cart) {
    return null;
  }
  const [priceLabel, setPriceLabel] = useState("");
  const [legalMessage, setLegalMessage] = useState("");

  const userLang = userLanguage();
  const intl = useIntl();

  useEffect(() => {
    setPriceLabel(
      intl.formatMessage({
        id: "cart.widget.price",
      })
    );
    setLegalMessage(
      intl.formatMessage({
        id: "cart.widget.legal",
      })
    );
  }, [intl, userLang]);

  const { totalPrice } = useSelector<AppState, ShoppingCartState>(
    state => state.shoppingCart
  );
  const onClickOrder = useCallback(() => navigate("/cart"), []);

  return (
    <>
      <CartWidgetContainer fromMenu={fromMenu}>
        <ProductText>
          {priceLabel}
          {/* <FormattedMessage id="cart.widget.price" /> */}
          <TaxText>
            <FormattedMessage id="cart.widget.tax" />
          </TaxText>
        </ProductText>
        <Price>{twoDecimals(totalPrice / 100)} €</Price>
        <LegalText>
          <div dangerouslySetInnerHTML={{ __html: legalMessage }} />
          {/* {legalMessage} */}
          {/* <FormattedHTMLMessage id="cart.widget.legal" /> */}
        </LegalText>
        <OrderButton onClick={onClickOrder}>
          <FormattedMessage id="cart.widget.order" />
        </OrderButton>
      </CartWidgetContainer>
      {!fromMenu && (
        <ProductsContainer>
          <ProductList />
        </ProductsContainer>
      )}
    </>
  );
};

export default CartWidget;
