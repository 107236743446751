import React, { FC } from "react";
import platform from "platform";
import Cookies from "universal-cookie";
import { FormattedHTMLMessage, useIntl } from "react-intl";

import ZipjetLogo from "../images/logo-zipjet";
import ButtonLink from "../../ui/button-link";
import {
  CenteredButtons,
  Centered,
  Logos,
  OverlayWrapper,
  HomeCross,
} from "./style";
import getAppLink from "../../utils/getAppLink";
import { WastLogo } from "../WastLogo";

const setOverlayCookie = (): void => {
  const cookies = new Cookies();
  cookies.set("mobile-overlay", true);
};

const Overlay: FC = () => {
  const intl = useIntl();
  let userOS = "";
  if (platform.os) {
    userOS = platform.os.family || "";
  }
  if (typeof window !== "undefined" && ["Android", "iOS"].includes(userOS)) {
    const cookies = new Cookies();
    const hadOverlay = cookies.get("mobile-overlay");
    if (hadOverlay) {
      return null;
    }
    return (
      <OverlayWrapper>
        <Logos>
          <WastLogo />
          <Centered>
            <HomeCross />
          </Centered>
          <ZipjetLogo />
        </Logos>
        <Centered>
          <FormattedHTMLMessage id="home.overlay.title" />
        </Centered>
        <CenteredButtons>
          <ButtonLink
            link={getAppLink()}
            text={intl.formatMessage({ id: "home.overlay.dl" })}
          />
          <br />
          <ButtonLink
            linkStyle
            onClick={setOverlayCookie}
            text={intl.formatMessage({ id: "home.overlay.continue" })}
          />
        </CenteredButtons>
      </OverlayWrapper>
    );
  } else {
    return null;
  }
};

export default Overlay;
