import React, { ComponentPropsWithRef, ReactNode, forwardRef } from "react";

import Wrapper from "./style";
import OInput, { Label } from "./style/input";
import { Left, Right, Placeholder } from "./style/custom";
import {
  TSaveOnBlur as TSaveOnBlurDeep,
  TIsValid as TIsValidDeep,
} from "./use-input";

export { useInput, UseInputResult, initialState, State } from "./use-input";
export {
  useTextArea,
  UseTextAreaResult,
  initialTextAreaState,
  TextAreaState,
} from "./use-textarea";
export {
  useCheckbox,
  UseCheckboxResult,
  initialCheckboxState,
  CheckboxState,
} from "./use-checkbox";
export { default as InputLoader } from "./loader";

export type TSaveOnBlur = TSaveOnBlurDeep;
export type TIsValid = TIsValidDeep;
export { Wrapper };

export interface Props extends ComponentPropsWithRef<"input"> {
  focused?: boolean;
  left?: ReactNode;
  label?: string;
  loading?: boolean;
  placeholderOnRight?: boolean;
  required?: boolean;
  right?: ReactNode;
  success?: boolean;
  valid?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      focused,
      label,
      left,
      placeholderOnRight,
      required = false,
      right,
      valid,
      ...props
    },
    ref
  ) => {
    return (
      <>
        { label ? <Label>{`${label}${required ? '*' : ''}`}</Label> : null}
        <Wrapper valid={valid} focused={focused} disabled={props.disabled}>
          {left && <Left>{left}</Left>}
          <OInput {...props} ref={ref} />
          {placeholderOnRight && props.value && (
            <Placeholder valid={valid}>{props.placeholder}</Placeholder>
          )}
          {right && <Right valid={valid}>{right}</Right>}
        </Wrapper>
      </>
    );
  }
);

Input.displayName = "Input";

Input.defaultProps = {
  valid: true,
  focused: false,
  loading: false,
  success: false,
  placeholderOnRight: false,
};

export default Input;
