import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { useWindowWidth } from "./use-window-width";

export function useIsDesktop(): boolean {
  const theme = useContext(ThemeContext);
  const windowWidth = useWindowWidth();

  return theme.breakpoints.desktopValue < windowWidth;
}
