import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

import { HttpResponse, post } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";

import { AppState } from "..";

export const sendNotation = (
  orderId: string, note: number | undefined, comment: string | undefined
): ThunkAction<Promise<HttpResponse<{}>>, AppState, {}, AnyAction> => async (
  _: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<{}>> => {
  let parameters = {};
  if (!note) {
    parameters = { isSkipped: true };
  } else if (note >= 4) {
    parameters = { rate: note };
  } else {
    parameters = { rate: note, comment: comment };
  }

  try {
    const response = await post<{}>(
      `/orders/${orderId}/rating`,
      parameters,
      getState()
    );
    return response;
  } catch (error) {
    return error;
  }
};
