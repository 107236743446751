// import platform from "platform";


export default (): string => {
  // let userOS = "";
  // if (platform.os) {
  //   userOS = platform.os.family || "";
  // }
  // const appLink = userOS === 'iOS' ? "https://apps.apple.com/fr/app/wast-le-pressing-mobile/id1380759819" : "https://play.google.com/store/apps/details?id=com.wast.wast";
  const appLink = "https://app.adjust.com/acsq0wm";

  return appLink;
};
