import styled from "styled-components";

export const Backdrop = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
`;

export const Close = styled.button`
  background: none;
  border: none;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0px;
  width: 24px;
  cursor: pointer;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    right: 20px;
    top: 20px;
  }
`;

export const ModalWrapper = styled.div`
  background-color: ${({ theme }): string => theme.colors.white};
  border-radius: 0px;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    border-radius: 8px;
    height: auto;
    max-height: 980px;
    width: 75%;
  }

  @media (max-width:${({ theme: { breakpoints } }): string => breakpoints.tabletValue}px) {
    border-radius: 8px;
    height: auto;
    max-height: 500px;
    width: 75%;
  }

  @media (max-width:${({ theme: { breakpoints } }): string => breakpoints.phoneValue}px) {
    border-radius: 0;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }
`;

export const ModalContainer = styled.div`
  h2 {
    margin: 0;
  }
`;

interface ContentProps {
  noPadding?: boolean;
}

export const Content = styled.div<ContentProps>`
  padding: ${({ noPadding }): string => (noPadding ? "0px" : "20px")};
`;

interface FooterProps {
  border?: boolean;
}

export const Footer = styled.div<FooterProps>`
  ${({ theme, border }): string =>
    border ? `border-top: 1px solid ${theme.colors.border}` : ``}
  padding: 20px;
  padding-bottom: 40px;
  right: 0;
  text-align: center;
`;

interface HeaderProps {
  border?: boolean;
}

export const Header = styled.div<HeaderProps>`
  ${({ theme, border }): string =>
    border ? `border-bottom: 1px solid ${theme.colors.border}` : ``};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  padding: 10px;
  padding-top: 20px;
  text-align: center;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    padding: 20px;
    padding-top: 40px;
  }
`;
