import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

import { HttpResponse, post, get, httpDelete, put } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";
import { AppState } from "../index";
import { PaymentMethod } from "../../types/payment-method";
import { ApiAddress } from "../../types/address";
import { AddressComponents } from "../address/types";
import {
  SET_ADDRESSES,
  DELETE_PAYMENT_METHOD,
  SET_ADDRESS,
  SET_ADDRESS_DETAILS,
  SET_USER,
  SET_PAYMENT_METHODS,
  UserActionTypes,
  SET_DEFAULT_PAYMENT_METHOD,
  SET_DEFAULT_LANGUAGE,
} from "./types";
import { setPaymentMethod } from "../shopping-cart/actions";
import { setCookie } from "react-use-cookie";

export const setUser = (
  reference: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string
): UserActionTypes => ({
  type: SET_USER,
  reference,
  firstName,
  lastName,
  email,
  phoneNumber,
});

export const createOneSignalProfile = (
  oneSignalUserId: string | null,
  email: string,
  phoneNumber: string
): ThunkAction<Promise<unknown>, AppState, {}, AnyAction> => async (
  _,
  getState: () => AppState
) => {
  try {
    const params: any = {
      email,
      phoneNumber,
      customerReference: getState().user.reference ?? "",
    };
    if (oneSignalUserId) {
      params.oneSignalUserId = oneSignalUserId;
    }
    await post("/customers/createOneSignalProfile", params, getState());
  } catch (error) {
    return error;
  }
};

export const setAddressAction = (
  placeId: string,
  type: string,
  description: string
): UserActionTypes => ({
  type: SET_ADDRESS,
  placeId,
  addressType: type,
  description,
});

export const setAddress = (
  placeId: string,
  description: string,
  type: string
): ThunkAction<Promise<HttpResponse<{}>>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<{}>> => {
  try {
    const response = await post<{}>(
      "/address-books",
      { placeId, notes: description },
      getState()
    );
    dispatch(setAddressAction(placeId, description, type));
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAccount = (): ThunkAction<
  Promise<HttpResponse<{}>>,
  AppState,
  {},
  AnyAction
> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<{}>> => {
  try {
    const response = await httpDelete<{}>(
      "/customers/deleteAccount",
      getState()
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const setAddressDetails = (details: string): UserActionTypes => ({
  type: SET_ADDRESS_DETAILS,
  details,
});

export const setPaymentMethods = (
  paymentMethods: PaymentMethod[]
): UserActionTypes => ({
  type: SET_PAYMENT_METHODS,
  paymentMethods,
});

export const deletePaymentMethodStore = (id: string): UserActionTypes => ({
  type: DELETE_PAYMENT_METHOD,
  id,
});

export const setDefaultPaymentMethodStore = (id: string): UserActionTypes => ({
  type: SET_DEFAULT_PAYMENT_METHOD,
  id,
});

export const setAddresses = (addresses: ApiAddress[]): UserActionTypes => ({
  type: SET_ADDRESSES,
  addresses,
});

interface GetPaymentMethodsResult {
  paymentMethods: PaymentMethod[];
}

type UserActionResponse<T> = Promise<HttpResponse<T> | void>;

export const getPaymentMethods = (): ThunkAction<
  Promise<HttpResponse<GetPaymentMethodsResult>>,
  AppState,
  {},
  AnyAction
> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<GetPaymentMethodsResult>> => {
  try {
    const response = await get<GetPaymentMethodsResult>(
      "/payment-methods",
      getState()
    );

    if (response.parsedBody) {
      dispatch(
        setPaymentMethods(
          response.parsedBody.paymentMethods.sort((a, b) => {
            if (a.isDefault) {
              return -1;
            }

            if (b.isDefault) {
              return 1;
            }

            return 0;
          })
        )
      );
    }

    return response;
  } catch (error) {
    return error;
  }
};

export const deletePaymentMethod = (
  id: string
): ThunkAction<Promise<HttpResponse<{}>>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<{}>> => {
  try {
    const response = await httpDelete<{}>(`/payment-methods/${id}`, getState());

    if (response.parsedBody) {
      dispatch(deletePaymentMethodStore(id));
    }

    return response;
  } catch (error) {
    return error;
  }
};

export const setDefaultPaymentMethod = (
  id: string
): ThunkAction<Promise<HttpResponse<{}>>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<{}>> => {
  try {
    const response = await put<PaymentMethod>(
      `/payment-methods/default/${id}`,
      {},
      getState()
    );

    if (response.parsedBody) {
      dispatch(setDefaultPaymentMethodStore(id));
      dispatch(setPaymentMethod(response.parsedBody));
    }

    return response;
  } catch (error) {
    return error;
  }
};

interface GetAddressesResult {
  addressBooks: ApiAddress[];
}

export const getAddresses = (
  refetch = false
): ThunkAction<
  UserActionResponse<GetAddressesResult>,
  AppState,
  {},
  AnyAction
> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): UserActionResponse<GetAddressesResult> => {
  try {
    if (
      refetch ||
      !getState().user.addresses ||
      !getState().user.addresses.length
    ) {
      const response = await get<GetAddressesResult>(
        "/address-books",
        getState()
      );

      if (response.parsedBody) {
        dispatch(setAddresses(response.parsedBody.addressBooks));
      }

      return response;
    }
  } catch (error) {
    return error;
  }
};

export const deleteAddress = (
  id: string
): ThunkAction<UserActionResponse<{}>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): UserActionResponse<{}> => {
  try {
    const response = await httpDelete<{}>(`/address-books/${id}`, getState());

    if (response.ok) {
      await dispatch(getAddresses(true));
    }

    return response;
  } catch (error) {
    return error;
  }
};

export const addAddress = (
  address: AddressComponents,
  type: string,
  notes: string
): ThunkAction<UserActionResponse<{}>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): UserActionResponse<{}> => {
  try {
    const response = await post<{}>(
      "/address-books",
      {
        address,
        type,
        ...(notes && notes.length > 0 && { notes }),
      },
      getState()
    );

    if (response.ok) {
      await dispatch(getAddresses(true));
    }

    return response;
  } catch (error) {
    return error;
  }
};

export const setDefaultLanguage = (lang: string): UserActionTypes => {
  setCookie("userLanguage", lang);
  return {
    type: SET_DEFAULT_LANGUAGE,
    language: lang,
  };
};
