import React, { FC } from "react";
import { useLocation } from "@reach/router";
import { FormattedMessage } from "react-intl";
import {
  ContainerBars,
  ContainerSteps,
  FilledBar,
  ShadowBar,
  Step,
  StepText,
  Wrapper,
} from "./style";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import qs from "query-string";


interface ProgressBarProps {
  step: number;
}

const withLoginStep = (token: string, step: number, withLoginParam: boolean): boolean => {  
  if (step === 1) {
    // if on the cart page, show login step only if there is no token
    return !token;
  }
  if(step === 2) {
    // if on the login page, always show login step
    return true;
  }
  // else, show login step if specified in the queryParams
  return withLoginParam;
}

const ProgressBar: FC<ProgressBarProps> = ({
  step,
}) => {
  const token = useSelector<AppState, string>(state => state.auth.token);
  const {search} = useLocation();
  const withLogin = qs.parse(search).displayLoginStep !== undefined;

  return (
    <Wrapper>
      <ContainerBars>
        <ShadowBar />
        <FilledBar withSignup={withLoginStep(token, step, withLogin)} step={step} />
      </ContainerBars>
      <ContainerSteps>
        <Step isActive={step >= 1}>
          <StepText>
            <FormattedMessage id="progress-bar.cart.text" />
          </StepText>
        </Step>
        {withLoginStep(token, step, withLogin) &&
          <Step isActive={step >= 2}>
            <StepText>
              {/* if we already went through this step, the text changes to "Connection" instead of "Inscription" */}
              <FormattedMessage id={step >= 3 ? "progress-bar.connexion.text" : "progress-bar.inscription.text"} />
            </StepText>
          </Step>
        }
        <Step isActive={step >= 3}>
          <StepText>
            <FormattedMessage id="progress-bar.order.text" />
          </StepText>
        </Step>
        <Step isActive={step >= 4}>
          <StepText>
            <FormattedMessage id="progress-bar.confirmation.text" />
          </StepText>
        </Step>
      </ContainerSteps>
    </Wrapper>
  );
};

export default ProgressBar;
