import styled from "styled-components";

const PROGRESS_BAR_HEIGHT = '10px';
const STEP_SIZE = '30px';

export const ContainerBars = styled.div`
  height: ${PROGRESS_BAR_HEIGHT};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - ${STEP_SIZE});
`;

export const ContainerSteps = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 50;
`;

interface WithStep {
  withSignup: boolean;
  step: number;
}

export const FilledBar = styled.div<WithStep>`
  background-color: ${({ theme }): string => theme.colors.secondary};
  height: ${PROGRESS_BAR_HEIGHT};
  position: absolute;
  top: 0;
  width: ${({ step, withSignup }): string => {
    switch (step) {
      case 2: return '34%';
      case 3: return withSignup ? '66%' : '50%';
      case 4: return '100%';
      case 1:
      default:
        return '0';
    }
  }};
  z-index: 10;
`;

export const ShadowBar = styled.div`
  background-color: ${({ theme }): string => theme.colors.grey };
  height: ${PROGRESS_BAR_HEIGHT};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

interface WithActive {
  isActive: boolean;
}

export const Step = styled.div<WithActive>`
  align-items: center;
  background-color: ${({ isActive, theme }): string => isActive ? theme.colors.secondary : theme.colors.grey };
  border-radius: 50%;
  color: ${({ isActive, theme }): string => isActive ? theme.colors.secondary : theme.colors.secondaryText };
  display: flex;
  height: ${STEP_SIZE};
  justify-content: center;
  width: ${STEP_SIZE};
  z-index: 20;
`;

export const StepText = styled.span`
  font-size: 16px;
  font-weight: bold;
  position: relative;
  top: 30px;
  white-space: nowrap;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${STEP_SIZE};
  position: relative;
  top: -10px;
  width: 100%;
`;
