import { FormattedMessage } from "react-intl";
import React, { FC } from "react";
import WastInfo from "../../config/wast";

import {
  InformationBlock,
  InformationExternalLink,
  InformationTitle,
} from "./style";

const Email: FC = () => (
  <InformationBlock>
    <InformationTitle>
      <FormattedMessage id="general.phone" />
    </InformationTitle>
    <InformationExternalLink href={`tel:${WastInfo.phone.raw}`}>
      {WastInfo.phone.formatted}
    </InformationExternalLink>
  </InformationBlock>
);

export default Email;
