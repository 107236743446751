import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from "react";
import { IntlProvider } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { AppState } from "../store";
import { Order } from "../types/order";
import { sendNotation } from "../store/order-notation/actions";
import Header from "./header";
import Footer from "./footer";
import MobileMenu from "./mobile-menu";
import NotationModal from "./notation-modal";
import DesktopMenu from "./desktop-menu";
import CartWidget from "./shopping-cart/cart-widget";
import ThemeProvider from "../theme/theme-provider";
import Overlay from "../components/home-overlay";
import "./layout.css";
import { ToastProvider } from "../ui/toast";
import GlobalStyle from "../ui/globals";
import { useIsAuth } from "../hooks/use-is-auth";
import { navigate } from "gatsby";
import useThunkDispatch from "../hooks/use-thunk-dispatch";
import { getOrders } from "../store/orders/actions";

import en from "../translations/en";
import fr from "../translations/fr";
import userLanguage from "./hooks/useLanguage";

const MainWrapper = styled.div`
  margin: 0 auto;
  padding-top: 0;
  overflow: hidden;
`;

const OverlayWrapper = styled.div`
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
`;

const messages: { [key: string]: { [key: string]: string } } = {
  en: en,
  fr: fr,
};

const getScrollbarWidth = (): number => {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    // eslint-disable-next-line
    // @ts-ignore
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    if (outer && outer.parentNode) outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  return 0;
};

interface LayoutProps {
  children: ReactNode;
  header: boolean;
  menuState?: string | null;
  cart?: boolean;
  requireAuth?: boolean;
  footer?: boolean;
  progressbar?: boolean;
  progressBarStep?: number;
}

const Layout: FC<LayoutProps> = ({
  children,
  header,
  menuState,
  cart,
  requireAuth = false,
  footer = true,
  progressbar = false,
  progressBarStep = 1,
}) => {
  const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);
  const orders = useSelector<AppState, Order[] | undefined>(
    state => state.orders.orders
  );
  const token = useSelector<AppState, string>(state => state.auth.token);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState<boolean>(
    true
  );
  const userLang = userLanguage();

  const isAuth = useIsAuth();
  const dispatch = useThunkDispatch();
  const toggleModal = useCallback(
    async answered => {
      setIsPaymentModalVisible(!isPaymentModalVisible);

      if (!answered && orders && orders.length > 0) {
        await dispatch(sendNotation(orders[0].id, undefined, undefined));
      }
    },
    [
      setIsPaymentModalVisible,
      isPaymentModalVisible,
      orders,
      dispatch,
      sendNotation,
    ]
  );

  useEffect(() => {
    setScrollbarWidth(getScrollbarWidth());

    if (requireAuth && !isAuth) {
      navigate("/signin");
    }

    if (!token) {
      return;
    }

    const fetchClasses = async (): Promise<void> => {
      await dispatch(getOrders(1));
    };

    fetchClasses();
  }, [dispatch, token, getOrders]);

  const messagesIUserLang = useMemo(() => messages[userLang], [userLang]);

  return (
    <IntlProvider locale={userLang} messages={messagesIUserLang}>
      <ThemeProvider>
        <GlobalStyle scrollbarWidth={scrollbarWidth} />
        <ToastProvider>
          {header && <Header />}
          <OverlayWrapper>
            <Overlay />
          </OverlayWrapper>
          <MainWrapper>
            <DesktopMenu
              menuState={menuState}
              progressBar={progressbar}
              progressBarStep={progressBarStep}
              activeHeader={header}
            />
            <CartWidget cart={cart} fromMenu />
            <MobileMenu menuState={menuState} />
            {orders &&
              orders.length > 0 &&
              orders[0].status === "COMPLETED" &&
              !orders[0].rating.isSkipped &&
              !orders[0].rating.rate && (
                <NotationModal
                  closeModal={toggleModal}
                  open={isPaymentModalVisible}
                  orderId={orders[0].id}
                />
              )}
            <main>{children}</main>
            {!footer ? <Footer onlyInfo /> : <Footer />}
          </MainWrapper>
        </ToastProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default Layout;
