import React, { FC } from 'react';
import ButtonStyle from './style';

export { ButtonStyle };

export interface Props {
  backgroundColor?: string;
  borderStyle?: string;
  fontSize?: string;
  link?: string;
  linkStyle?: boolean;
  onClick?: () => void;
  padding?: string;
  text: string;
  width?: string;
}

const ButtonLink: FC<Props> = ({
  backgroundColor,
  borderStyle = '57px',
  fontSize,
  link = '',
  linkStyle = false,
  onClick,
  padding = '',
  text,
  width = '286px',
}) => (
  <ButtonStyle
    as="a"
    backgroundColor={backgroundColor}
    borderStyle={borderStyle}
    fontSize={fontSize}
    href={link}
    linkStyle={linkStyle}
    onClick={onClick}
    padding={padding}
    width={width}
  >
    {text}
  </ButtonStyle>
);

export default ButtonLink;
