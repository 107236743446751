import React, { FC } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { useSelector } from 'react-redux';

import Home from "../images/Home.svg";
import Delivery from "../images/Delivery.svg";
import Factory from "../images/Factory.svg";
import Profil from "../images/Profil.svg";
import HomeBlue from "../images/Home-blue.svg";
import DeliveryBlue from "../images/Delivery-blue.svg";
import FactoryBlue from "../images/Factory-blue.svg";
import ProfilBlue from "../images/Profil-blue.svg";
import { AppState } from '../store';

const MobileMenuContainer = styled.div`
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.white};
  bottom: 0;
  display: flex;
  height: 80px;
  position: fixed;
  margin: 0;
  width: 100%;
  z-index: 1;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    display: none;
  }
`;

const MobileMenuLink = styled.a`
  color: ${({ theme: { colors } }): string => colors.primary};
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 10px;
  text-align: center;
  text-decoration: none;
  width: 100%;
`;

interface ActiveProps {
  active?: boolean;
}

const MobileMenuText = styled.div<ActiveProps>`
  margin-top: -10px;
  color: ${({ active, theme: { colors } }): string =>
    active ? colors.secondary : colors.primary};
`;

interface MobileMenuProps {
  menuState?: string | null;
}

const MobileMenu: FC<MobileMenuProps> = ({ menuState }) => {
  const token = useSelector<AppState, string>(state => state.auth.token);

  return (
    <MobileMenuContainer>
      <MobileMenuLink href="/">
        {('home' === menuState || null === menuState) ? <HomeBlue /> : <Home />}
        <MobileMenuText active={'home' === menuState || null === menuState}><FormattedMessage id="menu.home" /></MobileMenuText>
      </MobileMenuLink>
      <MobileMenuLink href="/pricing">
        {'delivery' === menuState ? <DeliveryBlue /> : <Delivery /> }
        <MobileMenuText active={'delivery' === menuState}><FormattedMessage id="menu.delivery" /></MobileMenuText>
      </MobileMenuLink>
      <MobileMenuLink href="/corporate">
        {'factory' === menuState ? <FactoryBlue /> : <Factory /> }
        <MobileMenuText active={'factory' === menuState}><FormattedMessage id="menu.factory" /></MobileMenuText>
      </MobileMenuLink>
      <MobileMenuLink href={token ? '/profile' : '/signin'}>
        {'profile' === menuState ? <ProfilBlue /> : <Profil /> }
        <MobileMenuText active={'profile' === menuState}><FormattedMessage id="menu.profil" /></MobileMenuText>
      </MobileMenuLink>
    </MobileMenuContainer>
  );
}

export default MobileMenu;
