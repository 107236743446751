import React, { useEffect, useState, useCallback, useMemo } from "react";
import Select from "react-select";
import { Modal } from "../modal";
import {
  AutocompleteContainer,
  Button,
  ButtonWrapper,
  GoogleMapReactWrapper,
  ConfirmAddressContent,
  ConfirmAddressHeader,
  Container,
  SelectContainer,
  NoteContainer,
  InputText,
} from "./style";
import GoogleMapReact from "google-map-react";
import Marker from "./map-marker";
import { FormattedMessage, FormattedHTMLMessage, useIntl } from "react-intl";
import AddressAutocomplete from "../address-autocomplete";
import { ExtendedGoogleMapsPlace, Position } from "../../store/address/types";
import { getLocationAvailability } from "../../store/address/actions";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import { navigate } from "gatsby";
import { AddressComponents } from "../../store/address/types";
import en from "../../translations/en";
import fr from "../../translations/fr";
import Input, { useInput } from "../../ui/input";
import { setAddressManuallyAdded } from "../../store/shopping-cart/actions";
import { setCartAddress } from "../../store/shopping-cart/actions";
import userLanguage from "../hooks/useLanguage";

const messages: { [key: string]: { [key: string]: string } } = {
  en: en,
  fr: fr,
};

export const getAddressTypes = (locale: string): { [key: string]: string } => ({
  HOME: messages[locale]["address.type.home"],
  OFFICE: messages[locale]["address.type.office"],
  OTHER: messages[locale]["address.type.other"],
})

interface ConfirmAddressProps {
  closeModal: () => void;
  open: boolean;
  initialPlace: ExtendedGoogleMapsPlace;
  onConfirmation?: (isAvailable: boolean) => void;
  addToProfile?: boolean;
  updateCartAddress?: boolean;
}

interface Value {
  value: string;
  label: string;
}

const ConfirmAddress = ({
  closeModal,
  open,
  initialPlace,
  onConfirmation,
  addToProfile,
  updateCartAddress = false,
}: ConfirmAddressProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useThunkDispatch();
  const locale = userLanguage();
  const addressTypes = useMemo(
    () => getAddressTypes(locale),
    [locale]
  );

  const [confirmedAddress, setConfirmedAddress] = useState<Position>(
    initialPlace.coordinates
  );
  const options = [
    {
      value: "HOME",
      label: addressTypes["HOME"],
    },
    {
      value: "OFFICE",
      label: addressTypes["OFFICE"],
    },
    {
      value: "OTHER",
      label: addressTypes["OTHER"],
    },
  ];

  const [typeSelected, setTypeSelected] = useState<string>("HOME");

  const [confirmedFormattedAddress, setConfirmedFormattedAddress] = useState<
    string | undefined
  >(initialPlace.formattedAddress);

  const [confirmedAddressComponents, setConfirmedAddressComponents] = useState<
    AddressComponents | undefined
  >(initialPlace.addressComponents);
  const [loadingAvailability, setLoadingAvailability] = useState<boolean>(
    false
  );

  const [addressNotes, setAddressNotes] = useState<string>("");

  const notesPros = useInput({});

  useEffect(() => {
    setConfirmedAddress(initialPlace.coordinates);
    setConfirmedFormattedAddress(initialPlace.formattedAddress);
    setConfirmedAddressComponents(initialPlace.addressComponents);
  }, [initialPlace]);

  const onPlaceChange = useCallback(
    (newPlace: ExtendedGoogleMapsPlace): void => {
      setConfirmedAddress(newPlace.coordinates);
      setConfirmedFormattedAddress(newPlace.formattedAddress);
      setConfirmedAddressComponents(newPlace.addressComponents);
    },
    [setConfirmedAddress, setConfirmedFormattedAddress]
  );

  const onTypeChange = useCallback(
    (value: string): void => {
      setTypeSelected(value);
    },
    [setTypeSelected]
  );

  const onNotesChange = useCallback(
    (value: string): void => {
      setAddressNotes(value);
    },
    [setAddressNotes]
  );

  const onConfirmAddress = async (): Promise<void> => {
    dispatch(setAddressManuallyAdded(true));
    setLoadingAvailability(true);
    const response = await dispatch(
      getLocationAvailability(
        confirmedAddress,
        confirmedFormattedAddress,
        confirmedAddressComponents,
        addToProfile,
        typeSelected,
        addressNotes
      )
    );
    setLoadingAvailability(false);
    if (updateCartAddress) {
      setCartAddress({
        coordinates: confirmedAddress,
        formattedAddress: confirmedFormattedAddress,
        addressComponents: confirmedAddressComponents,
      });
    }
    if (onConfirmation) {
      onConfirmation(response.ok);
    } else {
      closeModal();
      navigate(response && response.ok ? "/cart" : "/delivery-not-available");
    }
  };

  return (
    <Modal open={open} closeModal={closeModal}>
      <ConfirmAddressHeader border={false}>
        <FormattedHTMLMessage id="home.modal.title" />
      </ConfirmAddressHeader>
      {addToProfile && (
        <>
          <Container>
            <SelectContainer>
              <Select<Value>
                onChange={(values): void => {
                  values &&
                    !Array.isArray(values) &&
                    onTypeChange(values.value);
                }}
                options={options}
                value={{
                  value: typeSelected,
                  label: addressTypes[typeSelected],
                }}
              />
            </SelectContainer>
          </Container>
          <Container>
            <NoteContainer>
              <InputText>
                <FormattedMessage id="address.notes.text" />
              </InputText>
              <Input
                {...notesPros}
                onChange={(event): void => onNotesChange(event.target.value)}
                value={addressNotes}
                placeholder={intl.formatMessage({
                  id: "address.notes.placeholder",
                })}
              />
            </NoteContainer>
          </Container>
        </>
      )}
      <ConfirmAddressContent noPadding>
        <AutocompleteContainer>
          <AddressAutocomplete
            inputId="address-autocomplete"
            placeholder=""
            onPlaceChange={onPlaceChange}
            initialValue={initialPlace}
            border
          />
        </AutocompleteContainer>
        <GoogleMapReactWrapper>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.GATSBY_GOOGLE_API_KEY
                ? process.env.GATSBY_GOOGLE_API_KEY
                : "",
            }}
            center={confirmedAddress}
            defaultZoom={15}
            options={{
              fullscreenControl: false,
              zoomControl: false,
            }}
          >
            <Marker {...confirmedAddress} />
          </GoogleMapReact>
        </GoogleMapReactWrapper>
        <ButtonWrapper>
          <Button onClick={(): Promise<void> => onConfirmAddress()}>
            <FormattedHTMLMessage
              id={
                loadingAvailability
                  ? "home.modal.button.loading"
                  : "home.modal.button"
              }
            />
          </Button>
        </ButtonWrapper>
      </ConfirmAddressContent>
    </Modal>
  );
};

export default ConfirmAddress;
