import React, { FC } from "react";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { graphql, useStaticQuery } from "gatsby";
import { AppState } from "../../store";

import FooterHead from "./head";
import Address from "./address";
import Email from "./email";
import Information from "./information";
import Phone from "./phone";
import Pressings from "./pressings";
import Socials from "./socials";
import Services from "./services";
import { useIsDesktop } from "../../hooks/use-is-desktop";
import Wast from "./wast";
import { ContentfulData } from '../../../types/contentful'

import {
  Column,
  Copyright,
  InformationGrid,
  LogoWrapper,
  Title,
  Wrapper,
} from "./style";
import userLanguage from "../hooks/useLanguage";
import { WastLogo } from "../WastLogo";

interface FooterProps {
  onlyInfo?: boolean;
}

const UnconnectedFooter: FC = () => {
  const language = userLanguage();
  const {
    frWastparis,
    frServices,
    frPressingNord,
    frPressingSud,
    enWastparis,
    enServices,
    enPressingNord,
    enPressingSud
  }: ContentfulData = useStaticQuery(
    graphql`
      query {
        frWastparis: allContentfulPages(filter: {category: {eq: "WastParis"}, node_locale: {eq: "fr"}}) {
          edges {
            node {
              title
              slug
            }
          }
        }
        frServices: allContentfulPages(filter: {category: {eq: "Service"}, node_locale: {eq: "fr"}}, sort: {fields: title}) {
          edges {
            node {
              title
              slug
            }
          }
        }
        frPressingNord: allContentfulPages(filter: {category: {eq: "PressingNord"}, node_locale: {eq: "fr"}}, sort: {fields: title}) {
          edges {
            node {
              title
              slug
            }
          }
        }
        frPressingSud: allContentfulPages(filter: {category: {eq: "PressingSud"}, node_locale: {eq: "fr"}}, sort: {fields: title}) {
          edges {
            node {
              title
              slug
            }
          }
        }
        enWastparis: allContentfulPages(filter: {category: {eq: "WastParis"}, node_locale: {eq: "en"}}) {
          edges {
            node {
              title
              slug
            }
          }
        }
        enServices: allContentfulPages(filter: {category: {eq: "Service"}, node_locale: {eq: "en"}}, sort: {fields: title}) {
          edges {
            node {
              title
              slug
            }
          }
        }
        enPressingNord: allContentfulPages(filter: {category: {eq: "PressingNord"}, node_locale: {eq: "en"}}, sort: {fields: title}) {
          edges {
            node {
              title
              slug
            }
          }
        }
        enPressingSud: allContentfulPages(filter: {category: {eq: "PressingSud"}, node_locale: {eq: "en"}}, sort: {fields: title}) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `
  );
  const isDesktop = useIsDesktop();

  const wastparis = language === 'fr' ? frWastparis : enWastparis;
  const services = language === 'fr' ? frServices : enServices;
  const pressingNord = language === 'fr' ? frPressingNord : enPressingNord;
  const pressingSud = language === 'fr' ? frPressingSud : enPressingSud;

  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="footer.title" />
      </Title>
      <InformationGrid>
        <Column>
          <Address />
          <Email />
          <Phone />
        </Column>
        <Column>
          <Wast />
          <Services type="wast-paris" services={wastparis.edges} />
        </Column>
        <Column>
          <Services type="services" services={services.edges} />
        </Column>
        <Column>
          <Services type="pressingNord" services={pressingNord.edges} />
        </Column>
        <Column>
          <Services type="pressingSud" services={pressingSud.edges} />
        </Column>
        <Column>
          <Information />
          <Socials />
          {!isDesktop && <Pressings />}
        </Column>
      </InformationGrid>
      <LogoWrapper>
        <WastLogo />
      </LogoWrapper>
      <Copyright>
        <FormattedMessage
          id="footer.copyright"
          values={{ year: dayjs(new Date()).format("YYYY") }}
        />
      </Copyright>
    </Wrapper>
  );
}

const ConnectedFooter: FC = () => (
  <Wrapper>
    <InformationGrid>
      <Column>
        <Wast />
      </Column>
      <Column>
        <Information />
      </Column>
      <Column>
        <Socials />
      </Column>
    </InformationGrid>
    <Copyright>
      <FormattedMessage
        id="footer.copyright"
        values={{ year: dayjs(new Date()).format("YYYY") }}
      />
    </Copyright>
  </Wrapper>
);

const Footer: FC<FooterProps> = ({ onlyInfo }) => {
  const token = useSelector<AppState, string>(state => state.auth.token);
  return (
    onlyInfo ?
      <>
        <FooterHead />
      </>
      :
      <>
        <FooterHead />
        {token ? <ConnectedFooter /> : <UnconnectedFooter />}
      </>
  );
};

export default Footer;
