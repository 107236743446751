import styled from "styled-components";

const Input = styled.input`
  font-family: ${({ theme }): string => theme.fonts.main};
  color: ${({ theme: { colors } }): string => colors.textPrimary};
  opacity: ${({ disabled }): number => (disabled ? 0.7 : 1)};
  width: 100%;
  min-width: 0;
  margin: 0;
  border: none;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 8px 8px 20px;
  outline: 0;
  background-color: transparent;
  transition: 150ms;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }): string => theme.colors.placeholder};
  }
`;

export const Label = styled.label`
  font-size: .8em;
  font-weight: bold;
`;

export default Input;
