import React from "react";
import { MapMarker } from "./style/map-marker";

interface MarkerProps {
  lat: number;
  lng: number;
}
const Marker = ({  }: MarkerProps): JSX.Element => <MapMarker />;

export default Marker;
