import styled from 'styled-components';

interface BtnProps {
  as: string;
  href: string;
  linkStyle?: boolean;
  borderStyle?: string;
  width?: string;
  backgroundColor?: string;
  fontSize?: string;
  padding?: string;
};

const ButtonStyle = styled.button<BtnProps>`
  object-fit: contain;
  border-radius: ${({ borderStyle }): string => (borderStyle ? borderStyle : '57px')};
  font-size: ${({ fontSize }): string => (fontSize ? fontSize : '18px')};
  width: ${({ width }): string => (width ? width : '286px')};
  color: ${({ theme: { colors } }): string => colors.white};
  ${({ linkStyle, backgroundColor, theme: { colors } }): string => (linkStyle ? `` : `
    background-color: ${'dark' === backgroundColor ? colors.primaryOpacity : colors.secondary};
  `)}
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  ${({ padding }): string => padding ? `padding: ${padding};` : ""};
  height: 48px;
`;

export default ButtonStyle;
