import React, { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Modal } from "../modal";
import {
  ModalContainer,
  ModalContentContainer,
  NotationBlock,
  NotationIcon,
  Text,
  Title,
  Input,
  InputContainer,
  ValidateButtonContainer,
  ValidateButton,
  ValidateText,
} from "./style";
import Notation1 from "../../images/notation1.svg";
import Notation2 from "../../images/notation2.svg";
import Notation3 from "../../images/notation3.svg";
import Notation4 from "../../images/notation4.svg";
import Notation5 from "../../images/notation5.svg";
import TeeShirtIcon from "../../images/teeshirt.svg";

import { useTextArea } from '../../ui/input';
import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import { useToast, TOAST_TYPE } from "../../ui/toast";
import { sendNotation } from "../../store/order-notation/actions";

interface NotationModalProps {
  closeModal: (isAnswered?: boolean | null) => void;
  open: boolean;
  orderId: string;
}

const NotationModalProps: FC<NotationModalProps> = props => {
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const [comment, setComment] = useState(false);
  const [rate, setRate] = useState(0);
  const intl = useIntl();
  const inputProps = useTextArea();
  const { value: commentValue } = inputProps;

  const displayComment = useCallback((value) => {
    setComment(true);
    setRate(value)
  }, [setComment, setRate]);

  const validate = useCallback(async (value?: number) => {
    const response = await dispatch(sendNotation(props.orderId, value, commentValue));
    if (response.ok) {
      showToast(intl.formatMessage({ id: "notation.success" }), { type: TOAST_TYPE.SUCCESS });
    } else {
      showToast(response.error ? response.error.message : 'Error', { type: TOAST_TYPE.ERROR });
    }

    props.closeModal(true);
  }, [props.closeModal, sendNotation, commentValue]);

  return (
    <Modal {...props} required={true}>
      <ModalContainer>
        <ModalContentContainer>
          <TeeShirtIcon />
          <Title><FormattedMessage id="notation.title" /></Title>
          <Text><FormattedMessage id="notation.text" /></Text>
          <NotationBlock>
            <NotationIcon onClick={(): void => displayComment(1)}>
              <Notation1 />
            </NotationIcon>
            <NotationIcon onClick={(): void => displayComment(2)}>
              <Notation2 />
            </NotationIcon>
            <NotationIcon onClick={(): void => displayComment(3)}>
              <Notation3 />
            </NotationIcon>
            <NotationIcon onClick={(): Promise<void> => validate(4)}>
              <Notation4 />
            </NotationIcon>
            <NotationIcon onClick={(): Promise<void> => validate(5)}>
              <Notation5 />
            </NotationIcon>
          </NotationBlock>
          {comment && (
            <>
              <InputContainer>
                <Input {...inputProps} placeholder={intl.formatMessage({ id: "notation.comment" })} />
              </InputContainer>
              <ValidateButtonContainer>
                <ValidateButton onClick={(): Promise<void> => validate(rate)}>
                  <ValidateText><FormattedMessage id="notation.validate" /></ValidateText>
                </ValidateButton>
              </ValidateButtonContainer>
            </>
          )}
        </ModalContentContainer>
      </ModalContainer>
    </Modal>
  );
};

export default NotationModalProps;
