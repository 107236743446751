import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 10px 8px 10px 8px;
  border: solid 1px ${({ theme: { colors } }): string => colors.borders};
  border-radius: 3px;
  background-color: ${({ theme: { colors } }): string => colors.white};
`;

export const Line = styled.span`
`

export default Wrapper;
