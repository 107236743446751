import styled from "styled-components";

export const Social = styled.div`
  height: 30px;
  margin: 0 5px;
  width: 30px;
`;

export const InstagramBackground = styled.div`
  background-image: linear-gradient(
    to bottom,
    #515bd4,
    #8134af 26%,
    #dd2a7b 52%,
    #feda77 78%,
    #f58529
  );
  border-radius: 50%;
  padding: 5px;
`;

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px -5px 0;
`;

export const ShortSocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
