import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

export const WastLogo: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-atelier9.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />;
};
