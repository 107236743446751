import styled from 'styled-components';

export const ModalContainer = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
  margin-top: 50px;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NotationBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
`;

export const NotationIcon = styled.div`
  margin: 0 8px;
  cursor: pointer;

  @media ${({ theme: { breakpoints } }): string => breakpoints.tablet} {
    margin: 0 20px;
  }
`

export const Text = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 16px;
  color: ${({ theme: { colors } }): string => colors.primary};
  padding: 0 10px;
`;

export const Title = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 16px;
  color: ${({ theme: { colors } }): string => colors.primary};
  padding: 0 10px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const Input = styled.textarea`
  flex: 1;
  width: 100%;
  border-radius: 10px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  padding-left: 10px;
  padding-top: 10px;
  font-size: 14px;
`;

export const InputContainer = styled.div`
  width: 90%;
  margin-bottom: 40px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.tablet} {
    width: 60%;
  }
`;

export const ValidateButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 4px;
  height: 100px;
`;

export const ValidateButton = styled.div`
  padding: 12px 20px;
  cursor: pointer;
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  height: 48px;
  border-radius: 25px;
`;

export const ValidateText = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 16px;
  color: ${({ theme: { colors } }): string => colors.white};
`;
