/*
 * DO NOT MODIFY
 * Autogenerated image file by genimg.sh script.
 * To generated custom image copy template and use a name which is not
 * used as an image file name in the src/images folder
 */
import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Image: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "social-instagram-white.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />;
};

export default Image;
