import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { HttpResponse, get } from '../../utils/http';
import { AppState } from '..';
import { Position, ExtendedGoogleMapsPlace, AddressComponents } from './types';
import { ReduxDispatch } from '../../hooks/use-thunk-dispatch';
import { SET_ADDRESS, SetAddressActionTypes } from '../shopping-cart/types';
import { addAddress } from '../user/actions';

export const setAddress = (
  address: ExtendedGoogleMapsPlace | undefined
): SetAddressActionTypes => ({
  type: SET_ADDRESS,
  address: address,
});

export const getLocationAvailability = (
  { lat, lng }: Position,
  formattedAddress: string | undefined,
  addressComponents: AddressComponents | undefined,
  addToProfile: boolean | undefined,
  typeSelected: string | undefined,
  addressNotes: string | undefined
): ThunkAction<Promise<HttpResponse<{}>>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<{}>> => {
  try {
    const response = await get<{}>(
      `/availabilities?lat=${lat}&lng=${lng}`,
      getState()
    );

    if (!addToProfile) {
      dispatch(
        setAddress({
          coordinates: {
            lat,
            lng,
          },
          formattedAddress,
          addressComponents,
        })
      );
    } else if (
      undefined !== addressComponents &&
      undefined !== typeSelected &&
      undefined !== addressNotes
    ) {
      dispatch(addAddress(addressComponents, typeSelected, addressNotes));
    }

    return response;
  } catch (error) {
    return error;
  }
};

interface GetAddressFromCoordinatesResult {
  success: boolean;
  address: google.maps.GeocoderResult | undefined;
}

export const getAddressFromCoordinates = (
  { lat, lng }: Position,
  callback: (result: GetAddressFromCoordinatesResult) => void
): void => {
  const geocoder = new google.maps.Geocoder();
  const latlng = new google.maps.LatLng(lat, lng);

  geocoder.geocode({ location: latlng }, (results, status) => {
    if (status === google.maps.GeocoderStatus.OK) {
      if (results[0])
        return callback({
          success: true,
          address: results[0],
        });
    }

    return callback({
      success: false,
      address: undefined,
    });
  });
};
