import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { ShortSocials } from "./socials";
import Phone from "../../images/phone.svg";
import Mail from "../../images/mail.svg";
import Time from "../../images/time-grey.svg";
import {
  ContactItem,
  Contacts,
  HeadWrapper,
  Infos,
  Timetable,
  WastLogoContainer,
} from "./style";
import { WastLogo } from "../WastLogo";

const FooterHead: FC = () => {
  return (
    <HeadWrapper>
      <WastLogoContainer>
        <WastLogo />
      </WastLogoContainer>
      <Infos>
        <Contacts>
          <ContactItem>
            <Phone />
            <FormattedMessage id="footer.tel" />
          </ContactItem>
          <ContactItem>
            <Mail />
            info@wast.fr
          </ContactItem>
        </Contacts>
        {/* <Timetable>
          <Time />
          Lun-Ven : 7h-10h & 18h-22h | Sam : 9h-14h | Dim : 18h-22h
        </Timetable> */}
      </Infos>
      <ShortSocials />
    </HeadWrapper>
  );
};

export default FooterHead;
