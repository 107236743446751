import { FormattedMessage } from "react-intl";
import React, { FC } from "react";
import WastInfo from "../../config/wast";

import {
  InformationBlock,
  InformationExternalLink,
  InformationTitle,
} from "./style";

const Email: FC = () => {
  return (
    <InformationBlock>
      <InformationTitle>
        <FormattedMessage id="general.email" />
      </InformationTitle>
      <InformationExternalLink href={`mailto:${WastInfo.mail}`}>
        {WastInfo.mail}
      </InformationExternalLink>
    </InformationBlock>
  );
};

export default Email;
