import { ChangeEvent, useCallback, useReducer } from "react";

export interface State {
  dirty: boolean;
  focused: boolean;
  loading: boolean;
  success: boolean;
  valid: boolean;
  value: string;
}

export type TActions =
  | { type: "CHANGE"; value: string; valid: boolean }
  | { type: "FOCUS" }
  | { type: "BLUR" }
  | { type: "START_SUBMIT" }
  | { type: "END_SUBMIT" }
  | { type: "RESET_SUCCESS" }
  | { type: "SET_STATE"; state: Partial<State> };

function delay(ms: number): Promise<void> {
  return new Promise<void>(resolve => {
    setTimeout(resolve, ms);
  });
}

export const initialState = {
  dirty: false,
  focused: false,
  loading: false,
  success: false,
  valid: true,
  value: ""
};

export function reducer(state: State = initialState, action: TActions): State {
  switch (action.type) {
    case "CHANGE": {
      return {
        ...state,
        dirty: true,
        valid: action.valid,
        value: action.value
      };
    }
    case "BLUR": {
      return {
        ...state,
        focused: false
      };
    }
    case "FOCUS": {
      return {
        ...state,
        focused: true
      };
    }
    case "START_SUBMIT": {
      return {
        ...state,
        loading: true
      };
    }
    case "END_SUBMIT": {
      return {
        ...state,
        dirty: false,
        loading: false,
        success: true
      };
    }
    case "RESET_SUCCESS": {
      return {
        ...state,
        success: false
      };
    }
    case "SET_STATE": {
      return {
        ...state,
        ...action.state
      };
    }
    default:
      return state;
  }
}

export type TSaveOnBlur = (args: { value: string }) => void | Promise<void>;

export type TIsValid = (value: string) => boolean;

export interface UseInputParams {
  initialState?: Partial<State>;
  saveOnBlur?: TSaveOnBlur;
  resetDuration?: number;
  isValid?: TIsValid;
}

export interface UseInputResult extends State {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  onFocus: () => void;
  setState?: (state: Partial<State>) => void;
}

export function useInput({
  initialState: partialInitialState,
  saveOnBlur,
  resetDuration = 3000,
  isValid
}: UseInputParams = {}): UseInputResult {
  const [
    { value, focused, dirty, loading, success, valid },
    dispatch
  ] = useReducer(reducer, {
    ...initialState,
    ...partialInitialState
  });

  const onChange = useCallback(
    ({ target: { value: targetValue } }: ChangeEvent<HTMLInputElement>) =>
      dispatch({
        type: "CHANGE",
        valid: isValid ? isValid(targetValue) : true,
        value: targetValue
      }),
    [dispatch, isValid]
  );

  const onFocus = useCallback(() => dispatch({ type: "FOCUS" }), [dispatch]);

  const onBlur = useCallback(async () => {
    dispatch({ type: "BLUR" });

    // If saveOnBlur is defined, we trigger the async callback
    // that allows autosave on blur
    if (saveOnBlur && dirty && valid) {
      dispatch({ type: "START_SUBMIT" });

      await saveOnBlur({ value });

      dispatch({ type: "END_SUBMIT" });

      await delay(resetDuration);

      dispatch({ type: "RESET_SUCCESS" });
    }
  }, [dispatch, value, dirty, valid]);

  const setState = useCallback(
    (state: Partial<State>) => {
      dispatch({ type: "SET_STATE", state });
    },
    [dispatch]
  );

  return {
    dirty,
    focused,
    loading,
    onBlur,
    onChange,
    onFocus,
    success,
    valid,
    value,
    setState,
  };
}
