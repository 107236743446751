import React, { ReactNode, useEffect } from 'react';
import {
  Backdrop,
  Close,
  ModalWrapper,
  ModalContainer,
  Content,
  Footer,
  Header,
} from './style';
import CloseIcon from '../../images/Close.svg';

interface ModalProps {
  children?: ReactNode;
  closeModal: () => void;
  open: boolean;
  required?: boolean;
}

export const Modal = ({
  children,
  closeModal,
  open,
  required = false,
}: ModalProps): JSX.Element => {
  useEffect(() => {
    const htmlElement = document.getElementsByTagName('html')[0];

    if (htmlElement && open) {
      htmlElement.classList.add('no-scroll');
    } else {
      htmlElement.classList.remove('no-scroll');
    }
  }, [open]);

  return (
    <ModalContainer>
      {open && (
        <Backdrop>
          <ModalWrapper>
            {!required && (
              <Close
                onClick={(): void => {
                  if (closeModal) closeModal();
                }}
              >
                <CloseIcon />
              </Close>
            )}
            {children}
          </ModalWrapper>
        </Backdrop>
      )}
    </ModalContainer>
  );
};

interface ModalHeaderProps {
  border?: boolean;
  children: ReactNode;
}
export const ModalHeader = ({
  border,
  children,
}: ModalHeaderProps): JSX.Element => (
  <Header border={border}>{children}</Header>
);

interface ModalFooterProps {
  border?: boolean;
  children: ReactNode;
}
export const ModalFooter = ({
  border,
  children,
}: ModalFooterProps): JSX.Element => (
  <Footer border={border}>{children}</Footer>
);

interface ModalContentProps {
  children: ReactNode;
  noPadding?: boolean;
}
export const ModalContent = ({
  children,
  noPadding,
}: ModalContentProps): JSX.Element => (
  <Content noPadding={noPadding}>{children}</Content>
);
