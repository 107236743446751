import { AppState } from '../store';
import getLanguage from '../utils/getLanguage'
import { AddressComponents } from '../store/address/types';
import { Product } from '../types/product';

export interface HttpResponse<T> extends Response {
  parsedBody?: T;
  error?: Error;
}

interface Body {
  [key: string]: string | number | boolean | Body | AddressComponents | { product: Product; quantity: number }[];
}

export interface Error {
  request: string;
  code: string;
  message: string;
}

const baseUrl = process.env.GATSBY_API_URL;

const _http = <T>(path: string, token: string, args: RequestInit): Promise<HttpResponse<T>> => {
  const userLang = getLanguage();

  const request = new Request(`${baseUrl}${path}`, {
    ...args,
    headers: {
      Accept: 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
      'Accept-Language': userLang,
      'Content-Type': 'application/json',
    },
   
  });

  return new Promise((resolve, reject) => {
    let response: HttpResponse<T>;

    fetch(request)
      .then(res => {
        response = res;
        return res.json();
      })
      .then(body => {
        if (response.ok) {
          response.parsedBody = body;
          resolve(response);
        } else {
          response.error = body;
          reject(response);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const http = <T>(path: string, state: AppState | undefined = undefined, args: RequestInit): Promise<HttpResponse<T>> => {
  const token = state ? state.auth.token : '';
  return _http(path, token, args);
}

export const httpDirect = <T>(path: string, token: string, args: RequestInit): Promise<HttpResponse<T>> => {
  return _http(path, token, args);
}

export const get = <T>(path: string, state: AppState, args: RequestInit = {}): Promise<HttpResponse<T>> => {
  return http<T>(path, state, { ...args, method: 'get' });
};

export const httpDelete = <T>(path: string, state: AppState, args: RequestInit = {}): Promise<HttpResponse<T>> => {
  return http<T>(path, state, { ...args, method: 'delete' });
};

export const postDirect = <T>(
  path: string,
  body: Body,
  wastApiToken: string,
  args: RequestInit = {},
): Promise<HttpResponse<T>> => {
  return httpDirect<T>(path, wastApiToken, { ...args, method: 'post', body: JSON.stringify(body) });
};

export const post = <T>(
  path: string,
  body?: Body,
  state: AppState | undefined = undefined,
  args: RequestInit = {},
): Promise<HttpResponse<T>> => {
  return http<T>(path, state, {
    ...args,
    method: 'post',
    ...(body && {
      body: JSON.stringify(body),
    }),
  });
};

export const put = <T>(path: string, body: Body, state: AppState, args: RequestInit = {}): Promise<HttpResponse<T>> => {
  return http<T>(path, state, { ...args, method: 'put', body: JSON.stringify(body) });
};
